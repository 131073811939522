import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super("staticReports");
    }

    getStaticReports = filter => this.httpPostRequest('getReports', filter);

    saveStaticReport = report => this.httpPutRequest('saveStaticReport', report);

    getStaticReport = reportId => this.httpGetRequest(`getStaticReport/${reportId}`);

    deleteStaticReports = reportIds => this.httpPostRequest('deleteStaticReports', { reportIds });

    downloadResults = queryExecutionId => this.httpPostRequest(`getResultsUrl/${queryExecutionId}`);

    deleteReportGroups = request => this.httpPostRequest('deleteReportGroups', request);

    getTemplates = request => this.httpPostRequest('getTemplates', request);

    getScenarios = request => this.httpPostRequest('getScenarios', request);

    saveTemplate = ({ template }) => this.httpPutRequest('saveTemplate', template);

    deleteTemplates = ({ templateIds }) =>
        this.httpPostRequest('deleteTemplates', { templateIds });

    linkTemplateReports = ({ staticReportIds, templateId }) =>
        this.httpPostRequest('linkTemplateReports', { staticReportIds, templateId });

    unlinkTemplateReports = ({ staticReportIds, templateId }) =>
        this.httpPostRequest('unlinkTemplateReports', { staticReportIds, templateId });

    getTemplate = (idx) => this.httpGetRequest(`getTemplate/${idx}`);

    loadResults = scenarioIds => this.httpPostRequest("loadResults", scenarioIds);

    getTables = databaseName => this.httpPostRequest(`getTables/${databaseName}`);

    runQuery = (request, taskId) => this.httpPostRequest(`runQuery/${taskId}`, request);

    getAthenaQueriesHistories = filter => this.httpPostRequest("getAthenaQueriesHistories", filter);

    runReportsForScenarios = request => this.httpPostRequest("runReportsForScenarios", request);
}