import types from "common/actionTypes";

const defaultState = {
    taskId: null,
    showSidebar: true,
    lookups: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.REPORTING_SET_TASK_DATA:
            return {
                ...state,
                taskId: action.data.taskId,
            };
        case types.TOGGLE_SIDEBAR:
            return {...state, showSidebar: !state.showSidebar};
        default:
            return state;
    }
};
