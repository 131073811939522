import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";
import {fetchCasesSource} from "../actions/staticReportActions";

const defaultState = {
    showGridSpinner: false,
    modalHeader: "Edit tag",
    selectedIds: [],
    gridSource: {
        data: [],
        filter: {
            searchString: null,
            pageNumber: 1,
            pageSize: 50
        },
    },
    linksModal: {
        open: false,
        locked: false,
        lookup: [],
        tag: {},
        searchString: '',
        source: {
            data: [],
            total: 0
        }
    },

    linkModal: {
        open: false,
        locked: false,
    },
    link: {
        lookup: {},
        errors: {},
        links: {},
    },

    task: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.TAG_SET_GRID_SOURCE:
            return dotProp.set(state, "gridSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: source.filter,
            }));
        case types.TAG_CHANGE_GRID_FILTER:
            return dotProp.merge(state, "gridSource.filter", action.data.filter);
        case types.TAG_TOGGLE_GRID_SPINNER:
            return dotProp.set(state, "showGridSpinner", action.data.toggle);
        case types.TAG_SET_MODAL_HEADER:
            return dotProp.set(state, "modalHeader", action.data.modalHeader);
        case types.TAG_CHANGE_SELECTED:
            return dotProp.set(state, "selectedIds", action.data.selectedIds);
        case types.TAG_SET_TASK_DATA:
            return dotProp.set(state, "task", action.data);
        case types.TAG_TOGGLE_LINKS_MODAL_OPEN:
            return dotProp.set(state, "linksModal.open", action.data.toggle);
        case types.TAG_SET_LINKS_MODAL_LOOKUP:
            return dotProp.set(state, "linksModal.lookup", action.data);
        case types.TAG_TOGGLE_LINKS_MODAL_LOCKED:
            return dotProp.set(state, "linksModal.locked", action.data.toggle);
        case types.TAG_SET_LINKS_MODAL_SOURCE:
            return dotProp.set(state, "linksModal.source", action.data);
        case types.TAG_SET_LINKS_MODAL_TAG:
            return dotProp.set(state, "linksModal.tag", action.data);
        case types.TAG_CHANGE_LINKS_MODAL_SEARCH:
            return dotProp.set(state, "linksModal.searchString", action.data);

        case types.TAG_TOGGLE_LINK_EDIT_MODAL_OPEN:
            return dotProp.set(state, "linkModal.open", action.data.toggle);
        case types.TAG_TOGGLE_LINK_EDIT_MODAL_LOCKED:
            return dotProp.set(state, "linkModal.locked", action.data.toggle);
        case types.TAG_EDIT_LINK:
            return dotProp.merge(state, "link.links", action.data);
        case types.TAG_SET_LINK_LOOKUP:
            return dotProp.set(state, "link.lookup", action.data);
        case types.TAG_RESET_LINK:
            return dotProp.set(state, 'link', defaultState.link);
        case types.TAG_RESET_LINK_MODAL:
            return dotProp.set(state, 'linkModal', defaultState.linkModal);
        case types.TAG_SET_LINK_ERRORS:
            return dotProp.set(state, 'link.errors', action.data);
        default:
            return state;
    }
};
