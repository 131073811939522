import CubesService from "./cubesService";
import StaticReportsService from "./staticReportsService";
import SettingsService from "./settingService";
import PSOResults from "./psoResults";
import DashboardService from "./dashboardService";
import InstanceManagerService from "./instanceManagerService";
import AccountService from "./accountService";
import LookupService from "./lookupsService";
import ProjectService from "./projectMgmtService";
import TaskService from "./taskMgmtService";
import ScenarioService from "./scenarioMgmtService";
import ScenarioStatusService from "./scenarioStatusService";
import AuthService from "./authService";
import PSOLogsService from "./psoLogsService";
import ScenarioGroupService from "./scenarioGroupService";
import TagService from "./tagService";
import ProbesService from "./probesService";
import OTIService from "./otiService";
import ResourceAdequacyService from "./resourceAdequacyService";
import DashboardMgmtService from "./dashboardMgmtService";
import OneLineService from "./oneLineService";
import UserSettingsService from "./userSettingsService";

import EnelytixBillingService from "./enelytixBillingService";

export const cubesService = new CubesService();
export const staticReportsService = new StaticReportsService();
export const settingService = new SettingsService();
export const psoResultsService = new PSOResults();
export const dashboardService = new DashboardService();
export const instanceMrgSvc = new InstanceManagerService();
export const accountSvc = new AccountService();
export const lookupSvc = new LookupService();
export const projectSvc = new ProjectService();
export const taskSvc = new TaskService();
export const scenarioSvc = new ScenarioService();
export const scenarioStatusSvc = new ScenarioStatusService();
export const authSvc = new AuthService();
export const psoLogsService = new PSOLogsService();
export const scenarioGroupService = new ScenarioGroupService();
export const tagService = new TagService();
export const otiService = new OTIService();
export const probeService = new ProbesService();
export const resourceAdequacyService = new ResourceAdequacyService();
export const dashboardMgmtService = new DashboardMgmtService();
export const oneLineService = new OneLineService();
export const userSettingsSvc = new UserSettingsService();

export const enelytixBillingService = new EnelytixBillingService();
