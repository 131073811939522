import types from "common/actionTypes";
import { detailTypes } from "common/enums";
import dotProp from "dot-prop-immutable";

const defaultFilter = {
    pageSize: 10,
    pageNumber: 1,
    searchString: "",
};

const defaultState = {
    masterSource: {
        data: [],
        filter: {
            ...defaultFilter,
        },
    },
    masterSpinner: false,
    detailSpinner: false,
    reportsSpinner: false,
    masterSelected: [],
    detailSelected: [],

    detailLevel: detailTypes.linked,
    detailSource: {
        data: [],
        filter: {
            ...defaultFilter,
        },
    },
    reportSource: {
        data: [],
    },
    lookups: {},
    hasPendingChanges: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.CG_TOGGLE_MASTER_SPINNER:
            return dotProp.set(state, "masterSpinner", action.data.showSpinner);
        case types.CG_TOGGLE_DETAIL_SPINNER:
            return dotProp.set(state, "detailSpinner", action.data.showSpinner);
        case types.CG_TOGGLE_REPORTS_SPINNER:
            return dotProp.set(state, "reportsSpinner", action.data.showSpinner);
        case types.CG_SET_MASTER_SOURCE:
            return dotProp.set(state, "masterSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.CG_SET_REPORTS_SOURCE:
            return dotProp.set(state, "reportSource.data", action.data.data);
        case types.CG_SET_DETAIL_SOURCE:
            return dotProp.set(state, "detailSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.CG_MASTER_SELECTED:
            return dotProp.set(state, "masterSelected", action.data.idx != null ? [action.data.idx] : []);
        case types.CG_DETAIL_SELECTED:
            return dotProp.set(state, "detailSelected", action.data.idx != null ? [action.data.idx] : []);
        case types.CG_MASTER_CHECKED:
            return dotProp.set(state, "masterSelected", action.data.checkedIds);
        case types.CG_DETAIL_CHECKED:
            return dotProp.set(state, "detailSelected", action.data.checkedIds);
        case types.CG_SWITCH_DETAIL:
            return dotProp.set(state, "detailLevel", action.data.level);
        case types.CG_REPORTS_UPDATED:
            return dotProp.set(state, "reportSource.data", action.data.reports);
        case types.CG_SET_EDITED_STATE:
            return dotProp.set(state, "hasPendingChanges", !action.data.isClean);
        case types.CG_SET_REPORTS_FILTER:
            return dotProp.merge(state, "reportSource.filter", action.data);
        case types.CG_SET_MASTER_FILTER:
            return dotProp.merge(state, "masterSource.filter", action.data);
        case types.CG_SET_DETAIL_FILTER:
            return dotProp.merge(state, "detailSource.filter", action.data);
        case types.CG_SET_LOOKUPS:
            return dotProp.set(state, "lookups", action.data);
        default:
            return state;
    }
};
