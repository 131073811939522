import axios from 'axios';
import configuration, { loginRequest, isMsal } from '../config'
import { msalInstance } from '../index';

// Wire up interceptors
const instance = axios.create();

// intercepts unauthorized access
instance.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (401 === error.response.status) {
            if (isMsal) {
                // update auth state to get new access token when expires(Vistra only)
                // const oktaAuthentication = new OktaAuth(config.okta);
                //
                // return oktaAuthentication.authStateManager.updateAuthState()
                //     .then(() => {
                //         localStorage.setItem("token", oktaAuthentication.getIdToken());
                //         return instance.request(error.config);
                //     })
                const account = msalInstance.getAllAccounts()[0];
                const tokenResponse = await msalInstance.acquireTokenSilent({ ...loginRequest, account });
                error.config.headers['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
                return instance(error.config);

            } else {
                // redirect to login page when token expires
                // TODO : need to make use of refresh token in order to refresh JWT token
                localStorage.removeItem("token");
                window.location.replace("/login");
            }
        } else {
            return Promise.reject(error);
        }
    }
);

// adds token from local storage to request headers
instance.interceptors.request.use(
    async (config) => {
        if (isMsal) {
            const account = msalInstance.getAllAccounts()[0];
            const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account });

            config.headers.Authorization = `Bearer ${response.accessToken}`;

            // required for hangfire
            localStorage.setItem("token", response.accessToken);
        } else {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }

        return config;
    },
    error => {
        Promise.reject(error)
    });

export default class BaseService {
    constructor(endpoint, serverUrl = configuration.API_SERVER) {
        this.apiUrl = `${serverUrl}/${endpoint}`;
    }

    httpGetRequest = (apiMethod, headers = {}) =>
        instance.get([this.apiUrl, apiMethod].join('/'), { headers });

    //POST request wrapper. pass an object/array to it. (optional uri parameters)
    httpPostRequest = (apiMethod, requestBody, headers = {}) =>
        instance.post([this.apiUrl, apiMethod].join('/'), requestBody, { headers, maxContentLength: Infinity });

    // PUT request wrapper
    httpPutRequest = (apiMethod, requestBody, headers = {}) =>
        instance.put([this.apiUrl, apiMethod].join('/'), requestBody, { headers });

    // DELETE request wrapper
    httpDeleteRequest = (apiMethod, requestBody, headers = {}) =>
        instance.delete([this.apiUrl, apiMethod].join('/'), { data: requestBody }, { headers });

    // PATCH request wrapper
    httpPatchRequest = (apiMethod, requestBody, headers = {}) =>
        instance.patch([this.apiUrl, apiMethod].join('/'), requestBody, { headers });

    generateQueryString(parameters) {
        const params = new URLSearchParams();

        for (const [paramName, paramValue] of Object.entries(parameters)) {
            if (Array.isArray(paramValue)) {
                for (const arrayValue of paramValue) {
                    params.append(paramName, arrayValue.toString());
                }
            } else if (paramValue !== undefined) {
                params.append(paramName, paramValue.toString())
            }
        }

        return params.toString();
    }
}
