import React, { Fragment, Component } from 'react';
import makeModal from 'components/hoc/makeModal';
import {Button, Form, Label, Input, Row, FormFeedback, FormText} from 'reactstrap';

const ModalFooter = props => {
    const { onSubmit, onCancel } = props;

    return (<Fragment>
        <div className="d-flex justify-content-between p-2">
            <Button color="link" onClick={onCancel}>Close</Button>{' '}
            <Button color="primary" className="btn-link-dark" onClick={onSubmit}>Reset</Button>
        </div>
    </Fragment>)
}

class ModalBody extends Component {
    state = {
        passwordError: null,
        confirmPasswordError: null,
        currentPasswordError: null
    }

    constructor(props) {
        super(props);
    }

    validationRules = {
        currentPassword: [
            {
                validate: i => i.userId !== i.currentUser?.userId || i.currentPassword != null,
                errorMsg: `Current password is required`
            },
        ],
        password: [
            { validate: i => i.password != null, errorMsg: `Password is required` },
        ],
        confirmPassword: [
            { validate: i => i.confirmPassword != null, errorMsg: `Confirm your password` },
            { validate: i => i.confirmPassword === i.password, errorMsg: `Passwords mismatch.` },
        ]
    };

    setErrors = errors => {
        this.setState({
            passwordError: null,
            confirmPasswordError: null,
            currentPasswordError: null,
            ...errors
        });
    }

    resetForm = () =>
        this.setState({
            passwordError: null,
            confirmPasswordError: null,
            currentPasswordError: null
        });

    render() {
        const { source, onEdit } = this.props;
        const { currentUser, userId, password, confirmPassword, currentPassword } = source;
        const { currentPasswordError, passwordError, confirmPasswordError } = this.state;

        return (<Fragment>
            <Form className="p-2 mb-5">
                {currentUser?.userId === userId &&
                    <Row className="my-2">
                        <Label for="reset-current-password">Current password</Label>
                        <Input id="acc-form-current-password" placeholder="Current password" type="password"
                            onChange={e => onEdit({ currentPassword: e.target.value })} value={source.currentPassword || ''} />
                        {currentPasswordError && <FormText color="danger">{currentPasswordError}</FormText>}
                    </Row>}
                <Row className="my-2">
                    <Label for="reset-form-password">Password</Label>
                    <Input id="acc-form-password" placeholder="Password" type="password"
                        onChange={e => onEdit({ password: e.target.value })} value={password || ''} />
                    {passwordError && <FormText color="danger">{passwordError}</FormText>}
                </Row>
                <Row className="my-2">
                    <Label for="reset-confirm-password">Confirm password</Label>
                    <Input id="acc-form-confirm-password" placeholder="Confirm password" type="password"
                        onChange={e => onEdit({ confirmPassword: e.target.value })} value={confirmPassword || ''} />
                    {confirmPasswordError && <FormText color="danger">{confirmPasswordError}</FormText>}
                </Row>
            </Form>
        </Fragment>)
    }
}

export default makeModal(ModalBody, ModalFooter);