import types from "../common/actionTypes";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import dotProp from "dot-prop-immutable";

const defaultState = {
    generalSpinner: false,
    confirmationMessage: "",
    showConfirmation: false,
    onConfirmationSuccess: null,
    onConfirmationReject: null,

    docDownloadUrl: "",
    dashboardStatuses: [],
    jobStatuses: [],

    currentUser: {},
    logoUrl: null,

    navigation: [],
    sidebarMenu: [],

    isErrorModalOpen: false,
    errorModalMessage: null,
    errorModalDetailMessage: null,

    featuresConfiguration: {
        allowChangePassword: false,
        somEnabled: false,
        useSpotDefault: false,
        billingEnabled: false,
        otiEnabled: false
    },

    newPostAvailable: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.LAYOUT_SET_FEATURES:
            return dotProp.set(state, "featuresConfiguration", action.data);
        case types.LAYOUT_TOGGLE_GENERAL_SPINNER:
            return dotProp.set(state, "generalSpinner", action.data.showSpinner);
        case types.LAYOUT_SET_NAVIGATION:
            return dotProp.set(state, "navigation", action.data);
        case types.LAYOUT_SET_SIDEBAR_MENU:
            return dotProp.set(state, "sidebarMenu", action.data);
        case types.OPEN_ERROR_MODAL:
            return {
                ...state,
                isErrorModalOpen: true,
                errorModalMessage: action.data.errorMessage,
                errorModalDetailMessage: action.data.detailMessage,
            };
        case types.CLOSE_ERROR_MODAL:
            return {
                ...state,
                isErrorModalOpen: false,
                errorModalMessage: null,
                errorModalDetailMessage: null,
            };
        case types.OPEN_CONFIRMATION:
            return {
                ...state,
                onConfirmationSuccess: action.data.successCallback,
                onConfirmationReject: action.data.rejectCallback,
                confirmationMessage: action.data.message,
                showConfirmation: true,
            };
        case types.CLOSE_CONFIRMATION:
            return {
                ...state,
                onConfirmationSuccess: null,
                onConfirmationReject: null,
                confirmationMessage: "",
                showConfirmation: false,
            };
        case types.HUB_NOTIFICATION_JOBS:
            const newStatuses = filter(state.jobStatuses, s => s.projectId !== action.data.projectId).concat(action.data?.statuses ?? []);

            return dotProp.set(state, "jobStatuses", orderBy(newStatuses, ["processUid"], ["asc"]));
        case types.HUB_DASHBOARD_LOAD_PROGRESS_UPDATED:
            let processGuids = action.data.statuses.map(s => s.processUid);
            let oldStatuses = [...state.dashboardStatuses];

            return dotProp.set(
                state,
                "dashboardStatuses",
                orderBy(
                    [...filter(oldStatuses, s => !processGuids.includes(s.processUid)).concat(action.data.statuses)],
                    ["processUid"],
                    ["asc"]
                )
            );
        case types.LAYOUT_CLEAN_STATUSES:
            return dotProp.set(state, "dashboardStatuses", statuses => filter(statuses, s => s.processStatus !== 2));
        case types.LAYOUT_DISMISS_STATUS:
            return dotProp.set(state, "dashboardStatuses", statuses =>
                filter(state.dashboardStatuses, s => s.processUid !== action.data.processUid)
            );
        case types.LAYOUT_SET_USER:
            return dotProp.set(state, "currentUser", action.data);

        case types.LAYOUT_SET_LOGO_URL:
            return { ...state, ...action.data };

        case types.LAYOUT_SET_NEW_POST_CMS:
            return dotProp.set(state, "newPostAvailable", action.data);
        default:
            return state;
    }
};
