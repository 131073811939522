import { createStore, compose, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers/rootReducer";
import thunk from "redux-thunk";
import types from "../common/actionTypes";
import queryString from 'query-string';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from "connected-react-router";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import configuration from '../config';
import "babel-polyfill";
const connection = new HubConnectionBuilder()
    .withUrl(`${configuration.API_SERVER}/reportingHub`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => localStorage.getItem("token"),
    })
    .build();

connection.serverTimeoutInMilliseconds = 120000;

function signalRInvokeMiddleware() {
    return next => async action => {
        if (connection.state === "Disconnected") {
            await connection.start();
            await connection.invoke("Subscribe");
        }

        return next(action);
    };
}

function signalRRegisterCommands(store) {
    connection.on("Notify", status => {
        store.dispatch({ type: types.HUB_DASHBOARD_LOAD_PROGRESS_UPDATED, data: { statuses: [status] } });
    });

    connection.on("NotifyJobs", statusGroup => {
        store.dispatch({ type: types.HUB_NOTIFICATION_JOBS, data: statusGroup });
    });

    connection.on("NotifyQueryExecution", status => {
        store.dispatch({ type: types.PSO_LOGS_EVENT_STATUS, data: status });
    });
}

function preserveQueryParameters(history, preserve, location) {
    const currentQuery = queryString.parse(history.location.search);
    if (currentQuery) {
        const preservedQuery = {};
        for (let p of preserve) {
            const v = currentQuery[p];
            if (v) {
                preservedQuery[p] = v;
            }
        }
        if (location.search) {
            Object.assign(preservedQuery, queryString.parse(location.search));
        }
        location.search = queryString.stringify(preservedQuery);
    }
    return location;
}

function createLocationDescriptorObject(location, state) {
    return typeof location === 'string' ? { pathname: location, state } : location;
}

function createPreserveQueryHistory(createHistory, queryParameters) {
    return (options) => {
        const history = createHistory(options);
        const oldPush = history.push, oldReplace = history.replace;
        history.push = (path, state) => oldPush.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
        history.replace = (path, state) => oldReplace.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
        return history;
    };
}

const history = createPreserveQueryHistory(createBrowserHistory, ['filter'])();

const enhancers = [];
let middleware = [routerMiddleware(history), thunk, signalRInvokeMiddleware];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger({ collapsed: true }))
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(rootReducer(history), composedEnhancers);

signalRRegisterCommands(store);

export { store, connection, history };
