import BaseService from './baseService';


export default class DashboardMgmtService extends BaseService {
    constructor() {
        super('dashboard-management');
    }

    loadDashboards = scenarioIds => this.httpPostRequest('loadTables', scenarioIds);

    getLoadStatuses = scenarioIds => this.httpGetRequest(`getLoadStatuses/${scenarioIds}`);

    getListDashboards = () => this.httpGetRequest('listDashboards');

    getDashboardEmbedUrl = (taskId, dashboardId) => this.httpGetRequest(`getDashboardEmbedUrl/${taskId}/${dashboardId}`);

    getHeaders = (files) => {
        const formData = new FormData();
        formData.append('file', files[0]);

        return this.httpPostRequest('getHeaders', formData, { 'Content-Type': 'multipart/form-data' });
    }

    uploadDataFile = (data) => this.httpPostRequest('uploadDataFile', data, { 'Content-Type': 'multipart/form-data' });
}
