import React from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";
import makeModal from "components/hoc/makeModal";

class EditModal extends React.Component {
    render() {
        const { onEdit, source } = this.props;
        return (
            <div>
                <FormGroup row>
                    <Label sm={8} for="maxStaticReportMachines">
                        Max report instances{" "}
                    </Label>
                    <Col sm={4}>
                        <Input
                            type="number"
                            id="maxStaticReportMachines"
                            min={1}
                            max={100}
                            type="number"
                            step="1"
                            onChange={e => onEdit({ maxStaticReportMachines: parseInt(e.target.value) })}
                            value={source.maxStaticReportMachines || ''}
                        />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}

export default makeModal(EditModal);
