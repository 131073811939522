import BaseService from './baseService';

export default class UserSettingsService extends BaseService {
    constructor() {
        super('userSettings');
    }

    getSettings = userId => this.httpGetRequest(`${userId}/list`);

    updateSettings = (userId, settings) => this.httpPutRequest(`${userId}/update`, settings);

}
