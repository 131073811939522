import React from "react";
import Select, { components, ControlProps } from "react-select";


const SearchableSelect = ({
	source,
	onChange,
	label,
	value,
}) => { 
	const onSelect = (items) => {
		const arrItems = (items || []).map(x => x.value);

		if (typeof onChange === "function") {
			onChange(arrItems);
		}
	};

	const ControlComponent = (ControlProps) => (
		<div style={{ fontSize: '10px' }}>
			<p style={{ marginBottom: '5px' }}>{label}</p>
			<components.Control {...ControlProps} />
		</div>
	);

	const customStyles = {
		control: (base) => ({
			...base,
			minHeight: '30px',
			borderColor: 'grey',
			boxShadow: 'none',
			marginBottom: '10px',
			'&:hover': {
				borderColor: 'grey'
			},
		}),
		valueContainer: (provided) => ({
			...provided,
			padding: '0 3px',
		}),
		input: (provided) => ({
			...provided,
			margin: '0',
			padding: '0',
		}),
		multiValue: (base) => ({
			...base,
			height: '20px',
			'.multiValueLabel': {
				padding: '0',
			},
		}),
		option: (base) => ({
			...base,
			fontSize: '10px',
			boxShadow: 'none',
			'&:hover': {
				cursor: 'pointer',
			}
		}),
		noOptionsMessage: (base) => ({
			...base,
			fontSize: '10px',
		}),
		multiValueRemove: (base) => ({
			...base,
			'&:hover': {
				cursor: 'pointer',
				boreder: "none",
				backgroundColor: '#3c44b1',
				color: '#fff'
			},
		}),
	};

	const getOptionsByValues = (values, options) => {
		if (values) {
			return values.map(value => options.find(option => option.value === value));
		}

	}
	const selectedOptions = getOptionsByValues(value, source);

	return (
		<Select
			styles={customStyles}
			closeMenuOnSelect={true}
			components={{ Control: ControlComponent, DropdownIndicator: null }}
			isMulti
			onChange={onSelect}
			value={selectedOptions}
			options={source}
		/>
	);
}

export default SearchableSelect;