import BaseService from './baseService';
import config from '../config'

export default class extends BaseService {
    constructor() {
        super('auth', config.AUTH_SERVICE_URL);
    }

    login = ({ userName, password }) => this.httpPostRequest('getToken', { userName, password});
}
