import React, { Fragment, useState } from "react";
import {
  Button,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
} from "reactstrap";
import {
  faSyncAlt,
  faSearch,
  faChevronDown,
  faPlusCircle,
  faTimes,
  faEllipsisH,
  faPen,
  faUnlockAlt,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeGridView } from "components/hoc/makeGridView";
import cx from "classnames";

const GridItem = ({ item, actions, additionalSource }) => {
  const {
    userId,
    userName,
    email,
    projects,
    projectCodeNames,
    userRoles,
  } = item;
  const { editAccount, deleteAccount, resetPassword } = actions;
  const { allowChangePassword } = additionalSource;

  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);

  const onEdit = (e) => {
    e.preventDefault();
    editAccount(userId);
  };

  const onDelete = (e) => {
    e.preventDefault();
    deleteAccount(userId);
  };

  const onResetPassword = (e) => {
    e.preventDefault();
    resetPassword(userId);
  };

  return (
    <Fragment>
      <tr key={userId}>
        <td className="text-left">{userName}</td>
        <td className="text-left">{email}</td>
        <td className="text-left">
          {Array.isArray(projects) && projects.join(", ")}
        </td>
        <td className="text-left">
          {Array.isArray(projectCodeNames) && projectCodeNames.join(", ")}
        </td>
        <td className="text-left">
          {Array.isArray(userRoles) && userRoles.join(", ")}
        </td>
        <td className="text-center">
          <Dropdown
            isOpen={isOpen}
            toggle={toggle}
            className={cx({ "overflow-hidden": !isOpen })}
          >
            <DropdownToggle
              size="sm"
              color="neutral-dark"
              className=" px-2 py-0 no-caret"
            >
              <FontAwesomeIcon icon={faEllipsisH} className="font-size-lg" />
            </DropdownToggle>
            {/* "positionFixed" solving problem displaying dropdown before grid (child with z-index in parent with overflow: hidden)
                        See: https://github.com/reactstrap/reactstrap/issues/1098 */}
            <DropdownMenu
              right
              className="dropdown-menu-xl overflow-hidden p-0"
              positionFixed
            >
              <Nav pills className="nav-neutral-first flex-column pt-2 pb-3">
                <NavItem className="nav-item--header px-3">
                  <span className="text-capitalize text-black font-size-md font-weight-bold">
                    Actions
                  </span>
                </NavItem>
                <li className="dropdown-divider" />
                {typeof editAccount === "function" && (
                  <NavItem className="px-3">
                    <NavLink href="#" onClick={onEdit}>
                      <div className="nav-link-icon">
                        <FontAwesomeIcon icon={faPen} />
                      </div>
                      <span>Edit</span>
                    </NavLink>
                  </NavItem>
                )}
                {typeof deleteAccount === "function" && (
                  <NavItem className="px-3">
                    <NavLink href="#" onClick={onDelete}>
                      <div className="nav-link-icon">
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                      <span>Delete</span>
                    </NavLink>
                  </NavItem>
                )}
                {typeof resetPassword === "function" && allowChangePassword && (
                  <NavItem className="px-3">
                    <NavLink href="#" onClick={onResetPassword}>
                      <div className="nav-link-icon">
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </div>
                      <span>Reset password</span>
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
    </Fragment>
  );
};

const GridHeader = () => {
  return (
    <thead className="thead-dark">
      <tr>
        <th className="text-left sticky-top">User name</th>
        <th className="text-left sticky-top">Email</th>
        <th className="text-left sticky-top">Projects</th>
        <th className="text-left sticky-top">Project codes</th>
        <th className="text-left sticky-top">Role</th>
        <th className="text-center sticky-top">Actions</th>
      </tr>
    </thead>
  );
};

const GridActionsMenu = ({ additionalSource, actions }) => {
  const {
    changeSearchTerm,
    applyFilters,
    addAccount,
    refresh,
    onResetAll,
  } = actions;
  const { searchTerm, showGridSpinner, currentUser } = additionalSource;

  const onSearchChange = (e) => {
    e.persist();
    changeSearchTerm(e.target.value);
    applyFilters();
  };

  const onRefresh = (e) => {
    e.preventDefault();
    typeof refresh === "function" && refresh();
  };

  const onAddUser = (e) => {
    e.preventDefault();
    typeof addAccount === "function" && addAccount();
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between py-3">
        <div className="search-wrapper">
          <span className="icon-wrapper text-black">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input
            className="form-control form-control-sm rounded-pill"
            placeholder="Search terms..."
            type="search"
            onChange={onSearchChange}
            value={searchTerm || ""}
          />
        </div>
        <UncontrolledDropdown className="px-2">
          <DropdownToggle
            color="neutral-primary"
            size="sm"
            className="no-caret"
          >
            <span className="btn-wrapper--label">Actions</span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon
                icon={faChevronDown}
                className="opacity-8 font-size-xs ml-1"
              />
            </span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-xl">
            <Nav pills className="nav-neutral-dark flex-column px-3">
              <NavItem className="nav-item--header px-0">
                <span className="text-capitalize text-black font-size-md font-weight-bold">
                  Actions
                </span>
              </NavItem>
              <li className="dropdown-divider" />
              <NavItem>
                <NavLink href="#" onClick={onAddUser}>
                  <div className="nav-link-icon">
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </div>
                  <span>Add user</span>
                </NavLink>
              </NavItem>
              {currentUser?.isSuperAdmin && (
                <NavItem>
                  <NavLink href="#" onClick={onResetAll}>
                    <div className="nav-link-icon">
                      <FontAwesomeIcon icon={faPowerOff} />
                    </div>
                    <span>Reset passwords</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          tag="a"
          href="#"
          onClick={onRefresh}
          size="sm"
          color="link"
          className="text-primary"
          id="imRefreshBtn"
        >
          <FontAwesomeIcon
            icon={faSyncAlt}
            className="font-size-lg"
            spin={showGridSpinner}
          />
          <UncontrolledTooltip target="imRefreshBtn" placement="right">
            Refresh
          </UncontrolledTooltip>
        </Button>
      </div>
    </Fragment>
  );
};

export default makeGridView(GridItem, GridHeader, GridActionsMenu);
