import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super('taskMgt');
    }

    getTask = taskId => this.httpGetRequest(taskId);

    getTasks = filter => this.httpPostRequest('list', filter);

    putTask = project => this.httpPutRequest(null, project);

    deleteTask = taskId => this.httpDeleteRequest(taskId);

    archiveTask = taskId => this.httpPostRequest(`archive/${taskId}`);

    copyTask = task => this.httpPostRequest("copy", task);

    getLeadsLookup = () => this.httpGetRequest("leads");

    getScenariosLookup = (taskId, scenarioTypes) => this.httpGetRequest(`${taskId}/scenarios${scenarioTypes ? `?scenarioTypes=${scenarioTypes}` : ''}`);

    getScenarioGroupsLookup = taskId => this.httpGetRequest(`${taskId}/scenarioGroups`);

    updateDUPSRelease = taskId => this.httpPostRequest(`${taskId}/release/update`);

    createMarketModel = request => this.httpPostRequest("createMarketModel", request);

    restoreDb = taskId => this.httpPostRequest(`restore/${taskId}`);

    archiveDb = taskId => this.httpPostRequest(`archive/${taskId}`);

    initDb = taskId => this.httpPostRequest(`init/${taskId}`);

    runTask = taskId => this.httpPostRequest(`run/${taskId}`);

    validateTask = taskId => this.httpPostRequest(`validate/${taskId}`);

    datasetConvert = task => this.httpPostRequest("dataset-convert", task);

    forceDeleteTask = taskId => this.httpDeleteRequest(`forceDelete/${taskId}`);
}