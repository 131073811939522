import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import find from "lodash/find";
import filter from "lodash/filter";
import cx from "classnames";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConditionalWrapper from "./ConditionalWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const Select = ({
    source = [],
    isOptionDisabled = () => false,
    className,
    size,
    searchable = false,
    expandable,
    isNullable,
    isDisabled = false,
    defaultDisplay = "Not selected",
    onChange,
    value = null,
    caret = true,
}) => {
    const [isOpen, toggle] = useState(false);
    const [expanded, expand] = useState(false);
    const [keyword, setKeyword] = useState(null);

    const listSource = isNullable ? [{ key: null, displayName: defaultDisplay }].concat(source || []) : source;
    const displayItem = find(listSource, s => s.key === value);

    const onSelect = e => {
        const item = find(listSource, i => i.key === e.value);
        typeof onChange === "function" && item != null && onChange(item.key);
    };

    const onEdit = e => {
        const value = e.target.value;
        // Show items, if user is typing
        if (value && !isOpen) {
            toggle(true);
        }
        setKeyword(e.target.value);
    };

    // Close list, open search input
    const onStartSearch = () => {
        if (isOpen) {
            toggle(false);
        }
        setKeyword("");
    };

    // Close list, close search input
    const onEndSearch = () => {
        if (isOpen) {
            toggle(false);
        }
        setKeyword(null);
    };

    const onSubmit = e => {
        e.preventDefault();
    }

    const isTyping = keyword !== null;
    const toggleProps = isTyping ? { tag: "div" } : {};

    const showScroll = source?.length > 3;

    const filteredItems = filter(
        listSource,
        s => !isOptionDisabled(s) && (keyword === null || (s.displayName.toLowerCase().includes(keyword.toLowerCase()) || s.key.toString().includes(keyword)))
    );

    return (
        <Dropdown
            isOpen={isOpen && keyword !== ""} // Open dropdown when user click and search input inactive or has at least 1 character
            size={size}
            toggle={() => {
                if (keyword) setKeyword(null);
                toggle(!isOpen);
            }}
            disabled={isDisabled}
            className={cx(className, { "overflow-hidden": !isOpen })}
        >
            <DropdownToggle className={cx("w-100", { "p-0": isTyping, 'disabled': isDisabled })} color="light" caret={caret} {...toggleProps}>
                {" "}
                {isTyping ? (
                    <form onSubmit={onSubmit}>
                    <InputGroup size={size}>
                        <Input bsSize={size} onChange={onEdit} value={keyword} placeholder="Search..." autoFocus/>
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <span
                                    className="btn-wrapper--icon"
                                    onClick={e => {
                                        e.preventDefault();
                                        onEndSearch();
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                        </form>
                ) : (
                    <React.Fragment>
                        {(typeof displayItem !== "undefined" && displayItem.displayName) || defaultDisplay}
                        {searchable && !isDisabled && (
                            <span className="btn-wrapper--icon float-right">
                                <FontAwesomeIcon icon={faSearch} onClick={onStartSearch} />
                            </span>
                        )}
                    </React.Fragment>
                )}
            </DropdownToggle>
            <DropdownMenu
                right
                className={cx(showScroll ? "dropdown-scrollable" : "dropdown-no-scroll", { "pb-1": expandable })}
                style={{ height: expanded ? 465 : 170, overflow: "hidden" }}
            >
                <ConditionalWrapper
                    condition={showScroll}
                    wrapper={children => <PerfectScrollbar>{children}</PerfectScrollbar>}
                >
                    {filteredItems.map(s => (
                        <div key={s.key} role="menuitem">
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={e => {
                                    e.preventDefault();
                                    onSelect({ value: s.key });
                                    // Close search input, if user select items
                                    setKeyword(null);
                                    toggle(!isOpen);
                                }}
                            >
                                <small>{s.displayName}</small>
                            </a>
                        </div>
                    ))}
                    {filteredItems.length === 0 && (
                        <div role="menuitem">
                            <a className="dropdown-item disabled" href="#">
                                <small>No results</small>
                            </a>
                        </div>
                    )}
                </ConditionalWrapper>
                {expandable && filteredItems.length > 4 && (
                    <div
                        className="d-flex justify-content-center bg-white sticky-bottom"
                        style={{ borderTop: "1px solid rgba(0, 0, 0, 0.1)" }}
                    >
                        <FontAwesomeIcon
                            onClick={() => expand(!expanded)}
                            icon={expanded ? faAngleUp : faAngleDown}
                            size="xs"
                            style={{ cursor: "pointer" }}
                        />
                    </div>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default Select;
