import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";
import {otiManagementLevels} from "../common/enums";

const defaultFilter = {
    pageSize: 10,
    pageNumber: 1,
    searchString: "",
};

const defaultState = {
    masterSource: {
        data: [],
        filter: {...defaultFilter},
    },
    masterSpinner: false,
    detailSpinner: false,
    masterSelected: [],
    detailSelected: [],
    detailLevel: otiManagementLevels.history,
    detailSource: {
        data: [],
        filter: {...defaultFilter},
    },

    configurationModal: {
        open: false,
        locked: false,
        header: "",
        item: {},
        lookups: null,
        filters: null,
    },
    areasModal: { open: false }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.OTI_TOGGLE_MASTER_SPINNER:
            return dotProp.set(state, "masterSpinner", action.data.showSpinner);
        case types.OTI_TOGGLE_DETAIL_SPINNER:
            return dotProp.set(state, "detailSpinner", action.data.showSpinner);
        case types.OTI_SET_MASTER_SOURCE:
            return dotProp.set(state, "masterSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.OTI_SET_DETAIL_SOURCE:
            return dotProp.set(state, "detailSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.OTI_MASTER_SELECTED:
            return dotProp.set(state, "masterSelected", action.data.idx != null ? [action.data.idx] : []);
        case types.OTI_MASTER_CHECKED:
            return dotProp.set(state, "masterSelected", action.data.checkedIds)
        case types.OTI_DETAIL_SELECTED:
            return dotProp.set(state, "detailSelected", action.data.idx != null ? [action.data.idx] : []);
        case types.OTI_DETAIL_CHECKED:
            return dotProp.set(state, "detailSelected", action.data.checkedIds);
        case types.OTI_SWITCH_DETAIL:
            return dotProp.set(state, "detailLevel", action.data.level);
        case types.OTI_SET_MASTER_FILTER:
            return dotProp.merge(state, "masterSource.filter", action.data);
        case types.OTI_SET_DETAIL_FILTER:
            return dotProp.merge(state, "detailSource.filter", action.data);
        case types.OTI_RESET_MASTER_FILTER:
            return dotProp.merge(state, "masterSource.filter", defaultFilter);
        case types.OTI_RESET_DETAIL_FILTER:
            return dotProp.merge(state, "detailSource.filter", defaultFilter);

        case types.OTI_TOGGLE_CONFIGURATION_MODAL:
            return dotProp.toggle(state, "configurationModal.open");
        case types.OTI_TOGGLE_CONFIGURATION_MODAL_LOCKED:
            return dotProp.set(state, "configurationModal.locked", action.data.toggle);
        case types.OTI_SET_CONFIGURATION_MODAL_LOOKUP:
            return dotProp.set(state, "configurationModal.lookups", action.data);
        case types.OTI_SET_DATA_TYPE_FILTERS:
            return dotProp.set(state, "configurationModal.filters", action.data);
        case types.OTI_SET_CONFIGURATION_MODAL_SOURCE:
            return dotProp.set(state, "configurationModal.item", action.data);
        case types.OTI_SET_CONFIGURATION_MODAL_ERRORS:
            return dotProp.set(state, "configurationModal.errors", action.data);
        case types.OTI_SET_CONFIGURATION_MODAL_HEADER:
            return dotProp.set(state, "configurationModal.header", action.data);

        case types.OTI_TOGGLE_AREAS_EDIT_MODAL:
            return dotProp.toggle(state, 'areasModal.open')

        default:
            return state;
    }
};
