import types from "common/actionTypes";
import { psoResultsLevels } from "common/enums";
import dotProp from "dot-prop-immutable";

const defaultState = {
    // spinners
    masterSpinner: false,
    detailSpinner: false,

    // sources
    masterSource: {
        data: [],
        filter: {
            pageSize: 10,
            pageNumber: 1,
            searchString: "",
        },
    },
    detailSource: {
        data: [],
        filter: {
            searchString: "",
        },
    },

    // selections
    masterSelected: [],
    detailSelected: [],

    masterLevel: psoResultsLevels.cases,
    detailLevel: psoResultsLevels.files,

    reportsSpinner: false,
    hasTemplate: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.PSO_RESULTS_SET_BASE_INFO:
            return dotProp.set(state, "hasTemplate", action.data.hasTemplate);
        case types.PSO_RESULTS_SET_MASTER_SELECTED:
            return dotProp.set(state, "masterSelected", action.data.selectedIds);
        case types.PSO_RESULTS_TOGGLE_MASTER_SPINNER:
            return dotProp.set(state, "masterSpinner", action.data.showSpinner);
        case types.PSO_RESULTS_TOGGLE_DETAIL_SPINNER:
            return dotProp.set(state, "detailSpinner", action.data.showSpinner);
        case types.PSO_RESULTS_SET_DETAILS_SELECTED:
            return dotProp.set(state, "detailSelected", action.data.selectedIds);
        case types.PSO_RESULTS_SET_MASTER_FILTER:
            return dotProp.merge(state, "masterSource.filter", action.data);
        case types.PSO_RESULTS_SET_DETAIL_FILTER:
            return dotProp.merge(state, "detailSource.filter", action.data);
        case types.PSO_RESULTS_SET_MASTER_SOURCE:
            return dotProp.set(state, "masterSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.PSO_RESULTS_SET_DETAIL_SOURCE:
            return dotProp.set(state, "detailSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        default:
            return state;
    }
};
