import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super('api/scenarioGroups');
    }

    getLinks = filter => this.httpPostRequest(`links`, filter);

    saveLinks = ({ groupId, scenarioIds, action }) => this.httpPutRequest(`links`, { groupId, scenarioIds, action });

    getGroup = groupId => this.httpGetRequest(`${groupId}`);

    getGroups = filter => this.httpPostRequest('list', filter);

    runSOM = groupIds => this.httpPostRequest('runSOM', groupIds);

    stopSOM = groupIds => this.httpPostRequest('stopSOM', groupIds);

    runPSO = groupIds => this.httpPostRequest('runPSO', groupIds);

    generateSOMInputs = groupIds => this.httpPostRequest('generateInputs', groupIds);

    cancelSOMInputs = groupIds => this.httpPostRequest('cancelInputs', groupIds);

    saveGroup = group => this.httpPutRequest(null, group);

    deleteGroups = (groupIds, deleteScenarios) => this.httpPostRequest("delete", { groupIds, deleteScenarios });

    uploadInputs = (groupId, files) => {
        // Initial FormData
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('files[' + i + ']', file);
        }

        return this.httpPostRequest(`inputs/${groupId}/upload`, formData, { 'Content-Type': 'multipart/form-data' });
    }

    getInputFiles = groupId => this.httpGetRequest(`inputs/${groupId}`);

    inputsExist = (groupId, fileNames) => this.httpPostRequest(`inputs/${groupId}/exist`, fileNames);

    getInputUrls = (groupId, fileNames) => this.httpPostRequest(`inputs/${groupId}/urls`, fileNames);

    fetchLogUrl = groupId => this.httpGetRequest(`logs/${groupId}/url`);

    deleteInputFiles = (groupId, fileNames) => this.httpPostRequest(`inputs/${groupId}/delete`, fileNames);

    copyInputFiles = (sourceId, destinationId) => this.httpPostRequest(`inputs/${sourceId}/copy/${destinationId}`);

    getResultFiles = groupId => this.httpGetRequest(`results/${groupId}`);

    getResultUrls = (groupId, fileNames) => this.httpPostRequest(`results/${groupId}/urls`, fileNames);

    getExternalFunctions = taskId => this.httpGetRequest(`functions/${taskId}`);

    runExternalFunctions = request => this.httpPostRequest('functions', request)

    getFilesContent = request => this.httpPostRequest('filesContent', request);

    commitFilesChanges = request => this.httpPostRequest('saveFileChanges', request);

    getFilesSpecification = groupId => this.httpGetRequest(`filesSpecification/${groupId}`)
}
