import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";

const defaultState = {
    showSidebar: true,
    showGridSpinner: false,
    gridSource: {
        data: null,
        filter: {
            startDate: null,
            endDate: null,
            searchString: null,
            pageNumber: 1,
            pageSize: 30,
        },
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.ENELYTIX_BILLING_RESET_VIEW:{
            let newState = dotProp.set(state, 'gridSource.data', defaultState.gridSource.data);
            newState = dotProp.set(newState, 'gridSource.filter.pageNumber', defaultState.gridSource.filter.pageNumber);
            newState = dotProp.set(newState, 'gridSource.filter.searchString', defaultState.gridSource.filter.searchString);
            return newState;
        }
        case types.ENELYTIX_BILLING_SET_GRID_SOURCE:
            return dotProp.set(state, "gridSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: source.filter,
            }));
        case types.ENELYTIX_BILLING_CHANGE_GRID_FILTER:
            return dotProp.merge(state, "gridSource.filter", action.data.filter);
        case types.ENELYTIX_BILLING_TOGGLE_GRID_SPINNER:
            return dotProp.set(state, "showGridSpinner", action.data.toggle);
        default:
            return state;
    }
};
