import axios from "axios";

export const ExternalResourcesAPI = {
	postVisit: async (userName) => {
		const url = `https://cms.enelytix.com/wp-json/cms-custom-api/v1/visit/`;
		const data = { username: userName }
		return axios.post(url, data);
	},
};

