import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";
import { sortingDirection } from "common/enums";

const defaultState = {
    showGridSpinner: false,
    selectedIds: [],
    scenario: {},
    adjoinScenariosIds: [],
    gridSource: {
        data: [],
        filter: {
            searchString: null,
            includeStatus: true,
            pageNumber: 1,
            pageSize: 30,
            orderByColumn: "segment",
            sortingDirection: sortingDirection.asc
        },
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SS_SET_GRID_SOURCE:
            return dotProp.set(state, "gridSource", s => ({
                data: action.data.data,
                total: action.data.total,
                filter: s.filter,
            }));
        case types.SS_CHANGE_SELECTED:
            return dotProp.set(state, "selectedIds", action.data.selectedIds);
        case types.SS_CHANGE_GRID_FILTER:
            return dotProp.merge(state, "gridSource.filter", action.data.filter);
        case types.SS_TOGGLE_GRID_SPINNER:
            return dotProp.set(state, "showGridSpinner", action.data.toggle);
        case types.SS_SET_SCENARIO_DATA:
            return dotProp.set(state, "scenario", action.data);
        case types.SS_SET_ADJOIN_SCENARIOS:
            return dotProp.set(state, "adjoinScenariosIds", action.data)
        case types.SS_RESET:
            // reset state except page size
            return dotProp.set(defaultState, "gridSource.filter.pageSize", state.gridSource.filter.pageSize);
        default:
            return state;
    }
};
