import types from "common/actionTypes";
import { probeMasterTabs, probeDetailTabs } from "common/enums";
import dotProp from "dot-prop-immutable";

const defaultFilter = {
    pageSize: 10,
    pageNumber: 1,
    searchString: "",
};

const defaultState = {
    // spinners
    masterSpinner: false,
    detailSpinner: false,

    // sources
    masterSource: {
        data: [],
        filter: {
            ...defaultFilter,
        },
    },
    detailSource: {
        data: [],
        filter: {
            ...defaultFilter,
        },
    },
    probeGroupModal: {
        open: false,
        locked: false,
        item: {},
        errors: {}
    },

    runProbesModal: {
        open: false,
        locked: false,
        item: {},
        errors: {}
    },
    runProbesConfirmationDialog: {
        open: false
    },
    // selections
    masterSelected: [],
    detailSelected: [],

    masterLevel: probeMasterTabs.files,
    detailLevel: probeDetailTabs.caseGroups
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.PROBE_SET_MASTER_LEVEL:
            return dotProp.set(state, "masterLevel", action.data.level);
        case types.PROBE_SET_DETAIL_LEVEL:
            return dotProp.set(state, "detailLevel", action.data.level);
        case types.PROBE_SET_MASTER_SELECTED:
            return dotProp.set(state, "masterSelected", action.data?.selectedIds ?? []);
        case types.PROBE_SET_DETAIL_SELECTED:
            return dotProp.set(state, "detailSelected", action.data?.selectedIds ?? []);
        case types.PROBE_TOGGLE_MASTER_SPINNER:
            return dotProp.set(state, "masterSpinner", action.data.showSpinner);
        case types.PROBE_TOGGLE_DETAIL_SPINNER:
            return dotProp.set(state, "detailSpinner", action.data.showSpinner);
        case types.PROBE_SET_MASTER_SOURCE:
            return dotProp.set(state, "masterSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.PROBE_SET_DETAIL_SOURCE:
            return dotProp.set(state, "detailSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.PROBE_SET_MASTER_FILTER:
            return dotProp.merge(state, "masterSource.filter", action.data);
        case types.PROBE_SET_DETAIL_FILTER:
            return dotProp.merge(state, "detailSource.filter", action.data);
        case types.PROBE_RESET:
            return { ...defaultState };

        case types.PROBE_SET_PROBE_GROUP_MODAL_SOURCE:
            return dotProp.set(state, "probeGroupModal.item", action.data);
        case types.PROBE_SET_PROBE_GROUP_MODAL_ERRORS:
            return dotProp.set(state, "probeGroupModal.errors", action.data);
        case types.PROBE_TOGGLE_PROBE_GROUP_MODAL_OPEN:
            return dotProp.set(state, "probeGroupModal.open", action.data.toggle);
        case types.PROBE_TOGGLE_PROBE_GROUP_NODAL_LOCKED:
            return dotProp.set(state, "probeGroupModal.locked", action.data.toggle);

        case types.PROBE_SET_RUN_PROBES_MODAL_SOURCE:
            return dotProp.set(state, "runProbesModal.item", action.data);
        case types.PROBE_SET_RUN_PROBES_MODAL_ERRORS:
            return dotProp.set(state, "runProbesModal.errors", action.data);
        case types.PROBE_TOGGLE_RUN_PROBES_MODAL_OPEN:
            return dotProp.set(state, "runProbesModal.open", action.data.toggle);
        case types.PROBE_TOGGLE_RUN_PROBES_NODAL_LOCKED:
            return dotProp.set(state, "runProbesModal.locked", action.data.toggle);

        case types.PROBE_TOGGLE_RUN_PROBES_CONFIRMATION_DIALOG_OPEN:
            return dotProp.set(state, "runProbesConfirmationDialog.open", action.data.toggle);

        default:
            return state;
    }
};
