import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Draggable from 'react-draggable';
import uniqueId from 'lodash/uniqueId';
import BlockUi from 'react-block-ui';
import { ScaleLoader as Loader } from "react-spinners";


class DraggableModal extends Modal {
    renderModalDialog() {
        const { handle } = this.props;
        return (
            <Draggable handle={handle} scale={0.8}>
                {super.renderModalDialog()}
            </Draggable>
        )
    };
}

/**
 * @deprecated since 24.10.2023
 */
const makeModal = (ModalContentComponent, FooterTemplate) => {
    return class EditDialog extends React.Component {
        state = { source: {}, visible: false, errorMsg: [], locked: false };

        constructor(props) {
            super(props);

            this.contentRef = React.createRef();
        }

        open = (data) => {
            this.setState({
                visible: true,
                errorMsg: [],
                source: data ||  {}
            });
        }

        close = () => {
            this.setState({
                visible: false,
                errorMsg: [],
                source: {}
            });
        }

        addNotification({ errors }) {
            this.setState({ errorMsg: errors });
        }

        lock = () => this.setState({ locked: true });
        unlock = () => this.setState({ locked: false });
        updateModel = newState => this.setState({ source: { ...this.state.source, ...newState } });

        onCancel = () => {
            const { onCancel } = this.props;
            if (onCancel) {
                onCancel({ ...this.state.source });
            }

            this.close();
        }

        validateEntity = (entity, checks) => {
            const errors = {};
            for (let [key, rules] of Object.entries(checks)) {

                for (let rule of rules) {
                    if (!rule.validate(entity)) {
                        errors[`${key}Error`] = rule.errorMsg;
                        break;
                    }
                }
            }

            return errors;
        }

        onSubmit = () => {
            const { onSubmit } = this.props;
            const { setErrors, validationRules, resetForm } = this.contentRef.current;
            this.setState({ errorMsg: [] });

            typeof resetForm === 'function' && resetForm();
            if (validationRules !== undefined && typeof setErrors === 'function') {
                const errors = this.validateEntity(this.state.source, validationRules);
                if (Object.entries(errors).length > 0) {
                    setErrors(errors)
                    return;
                }
            }

            typeof onSubmit === 'function' && onSubmit({ ...this.state.source });
        }

        // general edit handler
        onEdit = newState => {
            this.setState(prevState => ({ source: { ...prevState.source, ...newState } }));
        }

        renderFooter = () => {
            return !this.props.readonly &&
                <ModalFooter>
                    <Button color="primary" onClick={this.onSubmit}>Save</Button>{' '}
                    <Button color="secondary" onClick={this.onCancel}>Cancel</Button>
                </ModalFooter>
        };

        render() {
            const { header, size, readonly, style, zIndex } = this.props;
            const { source, visible, errorMsg, locked } = this.state;

            const draggableId = `draggable-${uniqueId()}`;
            return (

                <DraggableModal
                    isOpen={visible}
                    handle={`#${draggableId}`}
                    backdrop="static"
                    size={size}
                    centered
                    zIndex={zIndex || 2000}
                    contentClassName='container overflow-visible'
                    style={style}>
                    <BlockUi tag="div" blocking={locked} loader={<Loader color={'var(--first)'} loading={locked} />}>
                        <ModalHeader
                            id={draggableId}
                            toggle={this.close}
                            className='move-cursor'>{header}</ModalHeader>

                        <ModalBody className='no-cursor' style={{ overflowY: 'inherit !important' }}>
                            {errorMsg?.length > 0 && <Alert color="danger">{errorMsg.map(error => <p className='mb-1'>{error}</p>)}</Alert>}
                            <ModalContentComponent ref={this.contentRef} {...this.props} source={source} onEdit={readonly ? null : this.onEdit} />
                        </ModalBody>

                        {typeof FooterTemplate !== 'undefined' ?
                            <FooterTemplate className="mb-5" onSubmit={this.onSubmit} onCancel={this.onCancel} /> :
                            this.renderFooter()}
                    </BlockUi >
                </DraggableModal >

            );
        }
    }
}


export default makeModal;
