import filter from "lodash/filter";
import { mapPeriods, mapUnitTypeModes, scopes, stackReportUnitTypeModes } from "./enums";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import random from "lodash/random";
import times from "lodash/times";
import tinycolor from "tinycolor2";
import axios from "axios";
import flatten from "lodash/flatten";
import { toast, Flip } from "react-toastify";
import dotProp from "dot-prop-immutable";
import { history } from "store/configureStore";
import { gridParams } from "common/enums";


const queryString = require('query-string');

// map lookup source
export function mapLookup(i) {
    return {
        key: i.id,
        displayName: i.displayName,
    };
}

// map multiselect source
export function mapMultiselectLookup(i) {
    return {
        idx: i.id,
        displayName: i.displayName,
    };
}

// generic check handler
export function applyCheckedChange(idx, isChecked, ids, checkedList) {
    if (!ids) {
        return [];
    }

    if (idx === -1) {
        const diff = filter(checkedList, x => !ids.includes(x));

        return isChecked ? ids.concat(diff) : diff;
    }

    return isChecked ? checkedList.concat(idx) : filter(checkedList, i => i !== idx);
}

export function createAndDownloadFile(filename, text) {
    const element = document.createElement("a");
    element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

// default backend request
export function fetch({
    spinnerToggle, // spinner action creator
    request, // request function
    callback, // callback after successful execution
    errorCallback,
    validationCallback,
    errorOnWarning = false, // treats error as warning
}) {
    return dispatch => {
        typeof spinnerToggle === "function" && spinnerToggle(true);

        return request()
            .then(r => {
                typeof spinnerToggle === "function" && spinnerToggle(false);

                // uses notifications from response
                parseNotifications({ warnings: r.data.warnings, errors: r.data.errors, infos: r.data.infos });
              
                // on error
                if (
                    r.data.errors &&
                    (r.data.errors.length > 0 || r.data.hasErrors || (r.data.hasWarnings && errorOnWarning))
                ) {
                    typeof errorCallback === "function" && errorCallback(r.data);
                    return;
                }
                
                typeof callback === "function" && callback(r.data);
            })
            .catch(reason => {
                typeof spinnerToggle === "function" && spinnerToggle(false);
                
                if (reason.response !== undefined) {
                    const { status, data } = reason.response;

                    if (status === 400 && data.errors?.length) {
                        typeof errorCallback === "function" && errorCallback(data.errors);
                    } else if (data.hasValidationErrors) {
                        typeof validationCallback === "function" && validationCallback(data.validationErrors);
                    } else if (data.hasErrors) {
                        parseNotifications({ errors: data.errors })
                    }

                    if (status === 403) {
                        window.location = "/forbidden";
                    }

                    // uses notifications from response
                    if (status >= 500) {
                        parseNotifications({ errors: ["Internal server error. Please, contact system administrator."] });
                    }
                }
                if (axios.isAxiosError(reason)) {
                    typeof errorCallback === "function" && errorCallback(reason.response?.data?.error ?? '', reason.response.data);
                } else {
                    typeof errorCallback === "function" && errorCallback(reason);
                }
            });
    };
}


// default backend request
export function fetchParallel({
    spinnerToggle, // spinner action creator
    requests, // request function
    callback, // callback after successful execution
}) {
    return dispatch => {
        typeof spinnerToggle === "function" && spinnerToggle(true);

        return axios
            .all(requests.map(r => r()))
            .then(r => {
                typeof spinnerToggle === "function" && spinnerToggle(false);
                const warnings = flatten(r.map(d => d.data.warnings));
                const errors = flatten(r.map(d => d.data.errors));
                const infos = flatten(r.map(d => d.data.infos));

                // uses notifications from response
                parseNotifications({ warnings, errors, infos });

                // on error
                if (errors && errors.length > 0) {
                    return;
                }

                typeof callback === "function" && callback(r.map(m => m.data));
            })
            .catch(reason => {
                typeof spinnerToggle === "function" && spinnerToggle(false);
                parseNotifications({ errors: ["Internal server error. Please, contact system administrator."] });
                console.log("Reason: ", reason);
            });
    };
}

// default backend request
export function fetchRequest({
    spinnerToggle, // spinner action creator
    request, // request function
    preRequest,
    callback, // callback after successful execution
    dispatchCallback = true, // uses a dispatch to callback
    errorOnWarning = false, // treats error as warning
}) {
    return dispatch => {
        if (spinnerToggle) {
            dispatch(spinnerToggle(true));
        }

        if (preRequest) {
            dispatch(preRequest());
        }

        return request()
            .then(r => {
                if (spinnerToggle) {
                    dispatch(spinnerToggle(false));
                }

                // uses notifications from response
                parseNotifications({ warnings: r.data.warnings, errors: r.data.errors, infos: r.data.infos });

                // on error
                if (
                    r.data.errors &&
                    (r.data.errors.length > 0 || r.data.hasErrors || (r.data.hasWarnings && errorOnWarning))
                ) {
                    return;
                }

                if (callback) {
                    if (dispatchCallback) {
                        dispatch(callback(r.data));
                    } else {
                        callback(r.data);
                    }
                }
            })
            .catch(reason => {
                if (spinnerToggle) {
                    dispatch(spinnerToggle(false));
                }

                parseNotifications({ errors: ["Internal server error. Please, contact system administrator."] });
                console.log("Reason: ", reason);
            });
    };
}

// map scenario backend response for lists.
export function mapScenarioItem(i) {
    return { ...i, idx: i.scenarioId };
}

// maps template backend response for lists


export function mapResultFileGroup(i) {
    return {
        idx: uniqueId(i.FileSize),
        groupName: i.GroupName,
        files: i.Files.map(f => ({ fileName: f.FileName, fileSize: f.FileSize, idx: uniqueId(f.FileName) })),
    };
}

export function mapDashboardScenarioItem(i) {
    return {
        key: i.scenarioId,
        displayName: i.scenarioName,
        state: i.state,
        startDate: new Date(i.startDate),
        endDate: new Date(i.endDate),
    };
}

export function mapDashboardItem(i) {
    let result = {};

    if (i.scenarios !== undefined)
        result.scenarios = JSON.parse(i.scenarios).map(o => ({
            key: random(Number.MAX_SAFE_INTEGER, false),
            displayName: o.scenario,
        }));

    if (i.unitTypes !== undefined)
        result.unitTypes = JSON.parse(i.unitTypes).map((o, idx) =>
            ({ key: idx, displayName: o.UnitType, mode: stackReportUnitTypeModes.max }));
    if (i.fuelTypes !== undefined)
        result.fuelTypes = JSON.parse(i.fuelTypes).map((o, idx) => ({ key: idx, displayName: o.PrimaryFuelType }));

    if (i.areas !== undefined) {
        const areas = JSON.parse(i.areas).map(o => ({ key: o.Area, displayName: o.Area, parent: o.ParentArea }));
        result.areas = buildTree(areas);
    }

    if (i.injector_stack !== undefined) {
        result.injectorStack = JSON.parse(i.injector_stack).map(o => ({
            avgOfMax: parseFloat(o.avg_of_max),
            avgOfP: parseFloat(o.avg_of_p),
            opRateAvg: parseFloat(o.oprate_avg),
            injector: o.inj,
            scenario: o.scenario,
            unitType: o.unittype,
            fuelType: o.fueltype,
            area: o.energyarea,
        }));
    }
    if (i.area_load !== undefined) {
        result.areasLoad = JSON.parse(i.area_load).map(o => ({
            avgAreaLoad: parseFloat(o.AvgAreasLoad),
            maxAreaLoad: parseFloat(o.MaxAreasLoad),
            area: o.area,
            scenario: o.scenario,
        }));
    }
    if (i.area_exports !== undefined) {
        result.areasExport = JSON.parse(i.area_exports).map(o => ({
            avgAreaExport: parseFloat(o.avgAreasExports),
            area: o.area,
            scenario: o.scenario,
        }));
    }

    return result;
}

export function mapMapItem(i) {
    let result = {};

    if (i.fuelTypes !== undefined)
        result.fuelTypes = JSON.parse(i.fuelTypes).map((o, idx) => ({ key: idx, displayName: o.PrimaryFuelType }));

    if (i.areas !== undefined) {
        const areas = JSON.parse(i.areas).map(o => ({ key: o.Area, displayName: o.Area, parent: o.ParentArea }));
        result.areas = buildTree(areas);
    }

    if (i.unitTypes !== undefined)
        result.unitTypes = JSON.parse(i.unitTypes).map((o, idx) =>
            ({ key: idx, displayName: o.UnitType, mode: mapUnitTypeModes.cap }));

    if (i.cycles !== undefined)
        result.cycles = JSON.parse(i.cycles).map((o, idx) => ({ key: idx, displayName: o.cycle }));

    if (i.areaHeatMap !== undefined) {
        result.areaHeatMap = JSON.parse(i.areaHeatMap).map(o => ({
            name: o.ara,
            cycle: o.cycle,
            loadPriceAvg: {
                [mapPeriods.daily]: parseFloat(o.loadprice_avg),
                [mapPeriods.offpeak]: parseFloat(o.offpeak_loadprice_avg),
                [mapPeriods.peak]: parseFloat(o.peak_loadprice_avg)
            },
            scenario: o.scn,
        }));
    }
    if (i.injectorHeatMap !== undefined) {
        const periodsMappings = [
            { prefix: '', key: mapPeriods.daily },
            { prefix: 'offpeak_', key: mapPeriods.offpeak },
            { prefix: 'peak_', key: mapPeriods.peak }
        ]
        const unitTypeModes = [
            { prefix: 'cap_', key: mapUnitTypeModes.cap },
            { prefix: 'max_', key: mapUnitTypeModes.max },
        ]

        const getLmpAvgEntries = o =>
            periodsMappings.map(({ prefix, key }) =>
                [key, Math.round(parseFloat(o[`${prefix}lmp_avg`]) * 1000) / 1000])

        const getPeriodEntries = (o, p) =>
            unitTypeModes.map(({ key, prefix }) =>
                [key, Math.round((parseFloat(o[`${p.prefix}p_avg`]) / (parseFloat(o[`${p.prefix}${prefix}avg`]) + 0.0001)) * 1000) / 1000])

        const getPCapEntries = o =>
            periodsMappings.map(p => [p.key, Object.fromEntries(getPeriodEntries(o, p))])

        result.injectorHeatMap = JSON.parse(i.injectorHeatMap).map(o => ({
            scenario: o.scenario,
            name: o.inj,
            // lmpAvg - round to 3 decimals
            lmpAvg: Object.fromEntries(getLmpAvgEntries(o)),
            pCap: Object.fromEntries(getPCapEntries(o)), // cap field can be 0, "* 1000 / 1000" - round to 3 decimals
            coordinates: [parseFloat(o.longitude), parseFloat(o.latitude)],
            capacity: parseFloat(o.cap_avg),
            area: o.energyarea,
            unitType: o.unittype,
            fuelType: o.fueltype,
            cycle: o.cyc,
        }));
    }

    return result;
}

export function mapDashboardFiles(files) {
    return files.map((o, i) => ({
        idx: i + 1,
        name: o.fileName,
        state: o.fileState,
    }));
}

export const mapMapSettings = data => ({ mapUrl: data.mapURL, iconsUrl: data.iconsURL, mapToken: data.mapToken });

// gets default reports
export function getDefaultReports(libraries) {
    return libraries.map(l => ({
        idx: l.libraryId,
        libraryId: l.libraryId,
        libraryName: l.libraryName,
        files: l.libraryFiles.map(f => ({
            scopeId: scopes.notUsed,
            fileId: f.libraryFileId,
            fileName: f.displayName,
            hasAggregationScope: f.hasAggregationScope,
        })),
    }));
}

export function mapReports(libraries, reports) {
    return libraries.map(l => ({
        idx: l.libraryId,
        libraryId: l.libraryId,
        libraryName: l.libraryName,
        files: l.libraryFiles.map(f => ({
            scopeId: find(reports, r => r.fileId === f.libraryFileId).scopeId,
            fileId: f.libraryFileId,
            fileName: f.displayName,
            hasAggregationScope: f.hasAggregationScope,
        })),
    }));
}

export const getCubeLibraries = libraries => {
    return libraries.map(library => {
        return {
            ...library,
            libraryFiles: library.libraryFiles.filter(file => file.forCube),
        };
    });
};

export const bytesToSize = bytes => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const buildTree = function (items) {
    // Build adjacency list from [[key, {parent: key, displayName: 'text'}]]
    let tree = new Map();

    for (let { key, parent, displayName } of items) {
        tree.set(key, { parent, children: [], displayName });
    }
    for (let { key, parent } of items) {
        let parentNode = tree.get(parent);
        if (parentNode) parentNode.children.push(key);
    }
    return tree;
};

export const findRoots = tree => [...tree].filter(([_, value]) => ["", null].includes(value.parent));

export const findParents = function (tree, values) {
    let nodeKeys = new Set(values);

    let parentKeys = [];
    let keys = findRoots(tree).map(([key]) => key);
    while (keys.length > 0) {
        const nodeKey = keys.shift();
        if (nodeKeys.has(nodeKey)) {
            parentKeys.push(nodeKey);
            continue;
        }
        const node = tree.get(nodeKey);
        keys.push(...node.children);
    }

    return parentKeys;
};

export const genGradient = function (rgba, steps, minAlpha = 0.2) {
    return times(steps, i => ({ ...rgba, a: (0.6 / steps) * (i + 1) + minAlpha }));
};

export const minMaxBy = function (objects, props = []) {
    /*
    Find min and max values for given `props` in array of objects.
    */
    const result = {};
    for (let prop of props) {
        result[`${prop}Max`] = Number.MIN_SAFE_INTEGER;
        result[`${prop}Min`] = Number.MAX_SAFE_INTEGER;
    }

    for (let obj of objects) {
        for (let prop of props) {
            result[`${prop}Max`] = obj[prop] > result[`${prop}Max`] ? obj[prop] : result[`${prop}Max`];
            result[`${prop}Min`] = obj[prop] < result[`${prop}Min`] ? obj[prop] : result[`${prop}Min`];
        }
    }

    return result;
};

export const normalize = function (value, min, max, a = 1, b = 2) {
    /*
    Normalize numeric value.
     */
    return a + ((value - min) * (b - a)) / (max - min);
};

export const array2Csv = function (data, header = true) {
    /*
    Convert array with objects to csv string
     */
    if (data == null || !data.length) {
        return "";
    }

    let result = "";
    let keys = Object.keys(data[0]);

    if (header) {
        result += keys.join(",");
        result += "\n";
    }
    for (let item of data) {
        result += keys.map(key => item[key]).join(",");
        result += "\n";
    }

    return result;
};

export const downloadFile = function (content, fileName = "data.txt", type = "text/plain") {
    /*
    Create in-memory file with given content and download it.
     */
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([content], { type }));

    a.setAttribute("download", fileName);
    a.click();

    URL.revokeObjectURL(a.href);
};

export const colorGradient = function (fade, startRgb, endRgb, fadeAlpha = false, defaultAlpha = 0.8) {
    const alpha = fadeAlpha ? startRgb.a + (endRgb.a - startRgb.a) * fade : defaultAlpha;

    return {
        r: parseInt(startRgb.r + (endRgb.r - startRgb.r) * fade),
        g: parseInt(startRgb.g + (endRgb.g - startRgb.g) * fade),
        b: parseInt(startRgb.b + (endRgb.b - startRgb.b) * fade),
        a: alpha,
    };
};

export const color2Style = rgb => ({ background: tinycolor(rgb).toRgbString() });

export const parseNotifications = ({ infos, warnings, errors }) => {
    if (infos) {
        infos.map(message => {
            toast.success(message, { containerId: "A", transition: Flip });
        });
    }

    if (warnings) {
        warnings.map(message => {
            toast.warn(message, { containerId: "A", transition: Flip });
        });
    }

    if (errors) {
        errors.map(message => {
            toast.error(message, { containerId: "A", transition: Flip });
        });
    }
};

export const displayErrors = function (errors) {
    if (errors) {
        parseNotifications({ errors: [].concat(errors) });
    }
};

export const displayValidationErrors = function (validationErrors) {
    if (validationErrors) {
        parseNotifications({ warnings: [].concat(validationErrors) });
    }
};

export function mapComparedWarningsTypes(i) {
    if (i.compareWarningsTypes.type === 'id') return;

    let warnings = [];
    let logLevels = {};
    let compareRules = {};

    for (let item of JSON.parse(i.compareWarningsTypes.value)) {
        // group by prefix
        const groups = groupBy(Object.entries(item), ([prop]) => {
            let parts = prop.split("_");
            parts.pop();
            return parts.join("_");
        });

        // Delete suffix for each prop in object - severity_123 -> severity, type_123 -> type, count_123 -> count
        let result = {};
        for (let [key, data] of Object.entries(groups)) {
            result[key] = Object.fromEntries(data.map(([keyId, value]) => [keyId.split("_").pop(), value]));
        }

        // Calculate number of warning types for each severity
        for (let [scenarioId, severity] of Object.entries(result.severity)) {
            if (severity !== null) {
                const countTypes = dotProp.get(logLevels, `${severity}.${scenarioId}`, 0);
                logLevels = dotProp.set(logLevels, `${severity}.${scenarioId}`, countTypes + 1);
            }
        }

        for (let [scenarioId, compareRule] of Object.entries(result.compare)) {
            if (compareRule !== null) {
                const countTypes = dotProp.get(compareRules, `${compareRule}`, 0);
                compareRules = dotProp.set(compareRules, `${compareRule}`, countTypes + 1);
            }
        }
        warnings.push(result);
    }

    return {
        warnings: {
            items: warnings
        },
        logLevels: {
            items: logLevels,
            selected: [...Object.keys(logLevels)]
        },
        compareRules: {
            items: compareRules,
            selected: [...Object.keys(compareRules)]
        }
    };
}

export function mapPSOLogsScenarioItem(i) {
    return {
        idx: i.scenarioId,
        scenarioName: i.scenarioName,
        state: i.state,
    };
}

export function mapWarningsTypes(result, scenarioId) {
    if (result.getWarningsTypes.type === 'id') return;
    
    let warnings = JSON.parse(result.getWarningsTypes.value).map(x => ({
        ...x,
        count: [parseInt(x.count)],
        severity: x.severity,
    }))

    let levels = Object.fromEntries(
        Object.entries(groupBy(warnings, "severity")).map(([level, items]) => [level, items.length])
    );

    warnings = warnings.map(x => (
        Object.fromEntries(Object.entries(x).map(([key, value]) =>
            ([key, { [scenarioId]: value }])))
    ));

    levels = Object.fromEntries(Object.entries(levels).map(([key, value]) =>
        ([key, { [scenarioId]: value }])));

    return {
        warnings: {
            items: warnings
        },
        logLevels: {
            items: levels,
            selected: [...Object.keys(levels)]
        }
    }
}

const queryStringConfig = { arrayFormat: 'comma' };

export function getPageParamsByKey(key) {
    if (!key) {
        console.error('No key provided for page parameters');
        return;
    }

    const pagesQueryParams = history.location.search.replace('?', '').split('|').filter(Boolean);
    const searchArr = pagesQueryParams.map((string) => queryString.parse(string, queryStringConfig));

    return {
        pagesQueryParams,
        pageQueryParams: searchArr.find((item) => item[gridParams.pageFilter] === key) || { [gridParams.pageFilter]: key },
    };
}

export function redirectWithSearchParams(newPageParamsArray) {
    if (!newPageParamsArray.find((item) => item[gridParams.pageFilter])) {
        console.error('Set "pageFilter" to redirect with search parameters');
        return null;
    }

    if (history.location.search.length > 1) {
        const pageParamsStringArray = history.location.search.replace('?', '').split('|');

        for (let i = 0; i < pageParamsStringArray.length; i++) {
            let pageParamsObject = queryString.parse(pageParamsStringArray[i], queryStringConfig);
            const pageFilter = newPageParamsArray.find((item) => item[gridParams.pageFilter])?.[gridParams.pageFilter];

            if (pageParamsObject[gridParams.pageFilter] === pageFilter) {
                pageParamsObject = newPageParamsArray.reduce((acc, item) => {
                    const [key, value] = Object.entries(item)[0];
                    acc[key] = value;

                    return acc;
                }, pageParamsObject);
            }

            pageParamsStringArray[i] = queryString.stringify(pageParamsObject, queryStringConfig);
        }

        history.replace({ search: `?${pageParamsStringArray.join('|')}` });
    } else {
        const pageParamsObject = newPageParamsArray.reduce((acc, item) => {
            const [key, value] = Object.entries(item)[0];
            acc[key] = value;

            return acc;
        }, {});

        history.replace({ search: `?${queryString.stringify(pageParamsObject, queryStringConfig)}` });
    }
}

export function doRedirect(pagesQueryParams, newPageParams) {
    if (pagesQueryParams.length === 0) {
        history.replace({ search: `?${queryString.stringify(newPageParams, queryStringConfig)}` });
        return;
    }

    for (let i = 0; i < pagesQueryParams.length; i++) {
        const queryObject = queryString.parse(pagesQueryParams[i], queryStringConfig);

        if (queryObject[gridParams.pageFilter] === newPageParams.pageFilter) {
            pagesQueryParams[i] = queryString.stringify(newPageParams, queryStringConfig);
        }
    }

    history.replace({ search: `?${pagesQueryParams.join('|')}` });
}

export function formatTimeSpan(period) {
    //period can be negative. For example -2h

    if (!period || period.startsWith('-')) {
        return '(deleting)';
    }

    let days = 0;
    let timeParts = period.split('.');
    let time;

    if (timeParts.length === 3) {
        // Format with days: '3.20:58:17.0400000'
        days = parseInt(timeParts[0], 10);
        time = timeParts[1];
    } else {
        // Format without days: '20:58:17.0400000'
        time = timeParts[0];
    }

    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (days > 36500) {
        return '';
    } else if (days > 0 && days < 36500) {
        return `(${days}d)`;
    } else if (days <= 0 && hours > 0) {
        return `(${hours}h)`;
    } else if (days <= 0 && hours <= 0 && minutes > 0) {
        return `(${minutes}m)`;
    } else {
        return '(deleting)';
    }
}