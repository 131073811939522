import types from "../common/actionTypes";
import { alertTypes } from "../common/enums";
import { fetch } from "common/helpers";
import {
  cubesService,
  settingService,
  accountSvc,
  scenarioSvc,
} from "../services/serviceStorage";
import some from 'lodash/some';
import { ExternalResourcesAPI } from "services/externalResourcesAPI";

export const cleanStatuses = () => ({ type: types.LAYOUT_CLEAN_STATUSES });
export const dismissStatus = (processUid) => ({
  type: types.LAYOUT_DISMISS_STATUS,
  data: { processUid },
});

export const setNavigation = (navigation) => ({
  type: types.LAYOUT_SET_NAVIGATION,
  data: navigation,
});

export const setPageTitle = (title) => ({
  type: types.LAYOUT_SET_TITLE,
  data: { title },
});

export const toggleGeneralSpinner = (showSpinner) => ({
  type: types.LAYOUT_TOGGLE_GENERAL_SPINNER,
  data: { showSpinner },
});

export const fetchScenario = ({ scenarioId, spinnerToggle, successCallback }) =>
  fetch({
    spinnerToggle,
    request: () => scenarioSvc.getScenario(scenarioId),
    callback: successCallback,
  });

// exports
export const addError = (msg) => addAlert(msg, alertTypes.error);
export const addSuccess = (msg) => addAlert(msg, alertTypes.success);
export const addInfo = (msg) => addAlert(msg, alertTypes.info);
export const addWarning = (msg) => addAlert(msg, alertTypes.warning);
export const clear = () => ({ type: types.CLEAR_ALERTS });
export const toggleAlerts = () => ({ type: types.TOGGLE_ALERTS });
export const dismiss = (idx) => ({ type: types.DISMISS_ALERT, data: { idx } });
export function showConfirmation({ message, successCallback, rejectCallback }) {
  return {
    type: types.OPEN_CONFIRMATION,
    data: { message, successCallback, rejectCallback },
  };
}

export const openErrorModal = ({ errorMessage, detailMessage }) => ({
  type: types.OPEN_ERROR_MODAL,
  data: { errorMessage, detailMessage },
});
export const closeErrorModal = () => ({ type: types.CLOSE_ERROR_MODAL });

export const setCurrentUser = (user) => ({
  type: types.LAYOUT_SET_USER,
  data: { ...user },
});

export const setLogUrl = (logoUrl) => ({
  type: types.LAYOUT_SET_LOGO_URL,
  data: { logoUrl },
});

export const defaultReportsUpdated = () => ({
  type: types.DEFAULT_REPORTS_UPDATED,
});

// save default reports
export const saveDefaultReports = ({
  taskId,
  reports,
  successCallback,
  spinnerToggle,
}) =>
  fetch({
    spinnerToggle,
    request: () => cubesService.saveDefaultReports(taskId, reports),
    callback: successCallback,
  });

// get default reports
export function getDefaultReports({ taskId, successCallback, spinnerToggle }) {
  return fetch({
    spinnerToggle,
    request: () => cubesService.getDefaultReports(taskId),
    callback: (r) => successCallback(r.result),
  });
}

export const getFeaturesConfiguration = ({ spinnerToggle, successCallback }) =>
  fetch({
    spinnerToggle,
    request: () => settingService.getFeaturesConfiguration(),
    callback: (r) => successCallback(r.result),
  });

export const setFeaturesConfiguration = (configuration) => ({
  type: types.LAYOUT_SET_FEATURES,
  data: configuration,
});

export const setMenu = menu => ({ type: types.LAYOUT_SET_SIDEBAR_MENU, data: menu });

// get settings
export function getSettings({ successCallback, spinnerToggle }) {
  return fetch({
    spinnerToggle,
    request: () => settingService.getSettings(),
    callback: (r) => successCallback(r.result),
  });
}

// modify settings
export function modifySettings({
  settings,
  successCallback,
  validationCallback,
  spinnerToggle,
}) {
  return fetch({
    spinnerToggle,
    request: () => settingService.modifySettings(settings),
    validationCallback: validationCallback,
    callback: successCallback,
  });
}

export function hideConfirmation() {
  return { type: types.CLOSE_CONFIRMATION };
}

export const fetchUser = ({ spinnerToggle, successCallback }) =>
  fetch({
    spinnerToggle,
    request: () => accountSvc.get(),
    callback: user => successCallback({
      ...user,
      isSuperAdmin: some(user.userRoles, r => r == "superadmin")
    }),
  });

export const notificationCallback = ({ warnings, errors, infos }) => {
  return (dispatch) => {
    if (infos) {
      infos.forEach((i) => {
        dispatch(addInfo(i));

        // to fade away alert in 3 secs
        setTimeout(() => {
          dispatch(dismiss(0));
        }, 3000);
      });
    }

    if (warnings) {
      warnings.forEach((i) => {
        dispatch(addWarning(i));
      });
    }

    if (errors) {
      errors.forEach((i) => {
        dispatch(addError(i));
      });
    }
  };
};

// helpers
const addAlert = (msg, alertType) => ({
  type: types.ADD_ALERT,
  data: { msg, alertType },
});

//get feeds from CMS
export const fetchFeedData = (username, successCallback, errorCallback) => {
  return async (dispatch) => {
    try {
      const response = await ExternalResourcesAPI.postVisit(username);
      successCallback(response.data)
    } catch (error) {
      errorCallback(error)
    }
  };
};

export const setNewPostAvailable = (data) => ({
  type: types.LAYOUT_SET_NEW_POST_CMS,
  data: data,
});