import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super("cubes");
    }

    // cube management
    getCube = (cubeId) =>
        this.httpGetRequest(`getCube/${cubeId}`);

    saveCube = (cube) =>
        this.httpPostRequest('saveCube', cube);

    deleteCubes = (cubeIds) =>
        this.httpPostRequest('deleteCubes', cubeIds);

    stopCubeJobs = (items) =>
        this.httpPostRequest('stop', items);

    restartCubeJobs = (item) =>
        this.httpPostRequest('restartJobs', item);

    restartCube = ({ cubeId }) =>
        this.httpPostRequest(`restart/${cubeId}`)

    getScenarios = (filter) =>
        this.httpPostRequest('getScenarios', filter);

    getCubes = (filter) =>
        this.httpPostRequest('getCubes', filter);

    getPartitionsStatus = (filter) =>
        this.httpPostRequest('getPartitionsStatus', filter);

    getReports = (scenarioId, cubeId) =>
        this.httpGetRequest(`getReports/${cubeId}/${scenarioId}`);

    saveReports = (combinations, reports) =>
        this.httpPostRequest('saveReports', { combinations, reports });

    deleteReports = (reports) =>
        this.httpPostRequest('deleteReports', reports);

    loadCubes = (cubeIds) =>
        this.httpPostRequest('loadCubes', cubeIds);

    loadScenarios = (scenarioIds) =>
        this.httpPostRequest('loadScenarios', scenarioIds);

    getSegmentLogsUrl = (historyId) =>
        this.httpGetRequest(`logs/${historyId}`);

    // templates 
    getTemplate = (templateId) =>
        this.httpGetRequest(`template/${templateId}`);

    saveTemplate = (template) =>
        this.httpPutRequest('template', template);

    deleteTemplates = (templateIds) =>
        this.httpPostRequest('deleteTemplates', templateIds);

    getTemplates = (filter) =>
        this.httpPostRequest('templatesList', filter);

    getTemplateReports = (templateId, cubeId) =>
        this.httpGetRequest(`templateReports/${templateId}/${cubeId}`);

    saveTemplateReports = (templateId, cubeIds, reports) =>
        this.httpPutRequest('templateReports', { templateId, cubeIds, reports });

    deleteTemplateReports = (templateId, cubeIds) =>
        this.httpPostRequest('deleteTemplateReports', { templateId, cubeIds });

    getMemoryStatuses = ({ cubeIds, scenarioIds }) =>
        this.httpPostRequest('memoryStatuses', { cubeIds, scenarioIds });

    // default reports
    getDefaultReports = (taskId) =>
        this.httpGetRequest(`defaultReports/${taskId}`);

    saveDefaultReports = (taskId, reports) =>
        this.httpPutRequest('defaultReports', { taskId, reports });


}
