import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";
import {sortingDirection, scenarioTypes} from "../common/enums";

const defaultState = {
    showGridSpinner: false,
    modalHeader: "Edit scenario",
    selectedIds: [],
    lookup: {
        items: [],
        selected: [],
    },
    gridSource: {
        data: [],
        filter: {
            keywords: [],
            pageNumber: 1,
            pageSize: 60,
            orderByColumn: "id",
            sortingDirection: sortingDirection.desc,
            scenarioTypes: scenarioTypes.allCases,  
        },
    },
    linksModal: {
        open: false,
        locked: false,
        scenario: {},
        source: {
            data: [],
            total: 0
        }
    },
    linkModal: {
        open: false,
        locked: false,
    },
    link: {
        lookup: {},
        errors: {},
        links: {},
    },
    externalFunctions: [],
    functionModal: {
        open: false,
        locked: false,
        source: {}
    },
    task: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SM_SET_GRID_SOURCE:
            return dotProp.set(state, "gridSource", {
                data: action.data.data,
                total: action.data.total,
                filter: action.data.filter,
            });
        case types.SM_CHANGE_GRID_FILTER:
            return dotProp.merge(state, "gridSource.filter", action.data.filter);
        case types.SM_TOGGLE_GRID_SPINNER:
            return dotProp.set(state, "showGridSpinner", action.data.toggle);
        case types.SM_SET_MODAL_HEADER:
            return dotProp.set(state, "modalHeader", action.data.modalHeader);
        case types.SM_SET_TASK_DATA:
            return dotProp.set(state, "task", action.data);
        case types.SM_CHANGE_SELECTED:
            return dotProp.set(state, "selectedIds", action.data.selectedIds);
        case types.SM_SET_LOOKUP:
            return dotProp.set(state, "lookup.items", action.data);
        case types.SM_SET_KEYWORDS:
            return dotProp.set(state, "lookup.selected", action.data);
        case types.SM_TOGGLE_LINKS_MODAL_OPEN:
            return dotProp.set(state, "linksModal.open", action.data.toggle);
        case types.SM_TOGGLE_LINKS_MODAL_LOCKED:
            return dotProp.set(state, "linksModal.locked", action.data.toggle);
        case types.SM_SET_LINKS_MODAL_SOURCE:
            return dotProp.set(state, "linksModal.source", action.data);
        case types.SM_SET_LINKS_MODAL_SCENARIO:
            return dotProp.set(state, "linksModal.scenario", action.data);
        case types.SM_RESET_GRID_DATA:
            const newState = dotProp.set(state, "selectedIds", []); 
            return dotProp.set(newState, "gridSource", { 
                data: [],
                total: 0,
                filter: { 
                    ...state.gridSource.filter,
                    pageNumber: 1,
                    resourceAdequacyGroupIds: undefined,
                    scenarioIds: undefined }
                });
        case types.SM_TOGGLE_LINK_EDIT_MODAL_OPEN:
            return dotProp.set(state, "linkModal.open", action.data.toggle);
        case types.SM_TOGGLE_LINK_EDIT_MODAL_LOCKED:
            return dotProp.set(state, "linkModal.locked", action.data.toggle);
        case types.SM_EDIT_LINK:
            return dotProp.merge(state, "link.links", action.data);
        case types.SM_SET_LINK_LOOKUP:
            return dotProp.set(state, "link.lookup", action.data);
        case types.SM_RESET_LINK:
            return dotProp.set(state, 'link', defaultState.link);
        case types.SM_RESET_LINK_MODAL:
            return dotProp.set(state, 'linkModal', defaultState.linkModal);
        case types.SM_SET_LINK_ERRORS:
            return dotProp.set(state, 'link.errors', action.data);
        case types.SM_SET_EXTERNAL_FUNCTIONS:
            return dotProp.set(state, "externalFunctions", action.data);
        case types.SM_TOGGLE_FUNCTION_MODAL_OPEN:
            return dotProp.set(state, "functionModal.open", action.data.toggle);
        case types.SM_SET_FUNCTION_MODAL_SOURCE:
            return dotProp.set(state, "functionModal.source", action.data);

        case types.SM_RESET:
            // reset state except page size
            return dotProp.set(defaultState, "gridSource.filter.pageSize", state.gridSource.filter.pageSize);

        default:
            return state;
    }
};
