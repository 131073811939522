import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super('probe');
    }

    getProbeGroups = filter => this.httpPostRequest('list', filter);

    getProbeGroupsHistory = filter => this.httpPostRequest('history', filter);

    getProbesHistory = filter => this.httpPostRequest('statuses', filter);

    getScenarioGroups = filter => this.httpPostRequest('scenarioGroups', filter);

    saveProbeGroup = group => this.httpPutRequest('', group);

    deleteProbeGroups = filter => this.httpPostRequest('delete', filter);

    runProbes = item => this.httpPostRequest('run', item);

    getProbesUrl = groupId => this.httpGetRequest(`downloadUrl/${groupId}`);

    uploadProbeFile = (groupId, file) => {
        const formData = new FormData();
        formData.append('file', file);
        return this.httpPostRequest(`upload/${groupId}`, formData, { 'Content-Type': 'multipart/form-data' });
    }
}
