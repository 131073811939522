import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";

const defaultState = {
    currentTab: 1,

    scenarios: [],
    selectedScenario: null,

    areas: [],
    selectedAreas: [],

    unitTypes: [],
    selectedUnitTypes: [],

    nodes: [],
    selectedNode: "",

    selectedRedrawNode : "", //Used when user dbclick on node to redraw

    selectedType: "",

    steps: 1,

    selectedDiagramItem: {},

    fromNodes: [],
    selectedFromNode: null,
    toNodes: [],
    selectedToNode: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.ONE_LINE_DIAG_SET_CURRENT_TAB:
            return dotProp.set(state, "currentTab", action.data);
        case types.ONE_LINE_DIAG_SET_SCENARIOS:
            return dotProp.set(state, "scenarios", action.data);
        case types.ONE_LINE_DIAG_SET_AREAS:
            return dotProp.set(state, "areas", action.data);
        case types.ONE_LINE_DIAG_SET_UNIT_TYPES:
            return dotProp.set(state, "unitTypes", action.data);
        case types.ONE_LINE_DIAG_SET_NODES:
            return dotProp.set(state, action.field, action.data);
        case types.ONE_LINE_DIAG_UPDATE_SELECTION:
            return dotProp.set(state, action.field, action.value);
        case types.ONE_LINE_DIAG_SET_DIAGRAM_ITEM:
            return dotProp.set(state, "selectedDiagramItem", action.data);
        case types.ONE_LINE_DIAG_RESET:
            return { ...defaultState };
        default:
            return state;
    }
};