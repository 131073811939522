import BaseService from './baseService';
import { scenarioSvc as service } from "./serviceStorage";

export default class extends BaseService {
    constructor() {
        super('scenario');
    }

    getScenario = scenarioId => this.httpGetRequest(`${scenarioId}`);

    getScenarios = filter => this.httpPostRequest('list', filter);

    putScenario = scenario => this.httpPutRequest(null, scenario);

    deleteScenarios = scenarioIds => this.httpPostRequest("delete", scenarioIds);

    runScenarios = scenarioIds => this.httpPostRequest("run", scenarioIds);

    validateScenarios = scenarioIds => this.httpPostRequest("validate", scenarioIds);

    stopScenarios = scenarioIds => this.httpPostRequest("stop", scenarioIds);

    restartFailedCases = scenarioIds => this.httpPostRequest(`restart`, scenarioIds);

    copyInputs = ({ sourceId, destinationId }) => this.httpPostRequest("copyInputs", { sourceId, destinationId });

    getInputs = scenarioId => this.httpGetRequest(`getInputs/${scenarioId}`);

    deleteInputs = ({ scenarioId, files }) => this.httpPostRequest(`deleteInputs`, { files, scenarioId });

    checkInstanceCapacity = scenarioIds => this.httpPostRequest(`checkInstanceCapacity`, scenarioIds);

    getLinks = filter => this.httpPostRequest(`links`, filter);

    getExternalFunctions = taskId => this.httpGetRequest(`functions/${taskId}`);

    runExternalFunctions = request => this.httpPostRequest('functions', request);

    loadResults = scenarioIds => this.httpPostRequest('loadResults', scenarioIds);

    runForceMerge = scenarioIds => this.httpPostRequest('forceMerge', scenarioIds);

    uploadInputs = ({ scenarioId, files }) => this.httpPostRequest(`uploadInputs/${scenarioId}`, files, { 'Content-Type': 'multipart/form-data' });

    downloadInputs = ({ scenarioId, files }) => this.httpPostRequest(`downloadInputs`, { files, scenarioId });
}
