import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super('scenarioStatus');
    }

    getStatuses = filter => this.httpPostRequest('list', filter);

    getLogs = historyId => this.httpGetRequest(`logs/${historyId}`);

    getErrorLogs = historyId => this.httpGetRequest(`errorLogs/${historyId}`);

    getResults = historyId => this.httpGetRequest(`results/${historyId}`);

    restartSegment = historyId => this.httpPostRequest(`restart/${historyId}`);

    restartFailedSegments = scenarioId => this.httpPostRequest(`restartSegments/${scenarioId}`);

    stopSegment = historyId => this.httpPostRequest(`stop/${historyId}`);

    manualError = ({ scenarioId, segmentIds }) => this.httpPostRequest(`manualErrors/${scenarioId}`, segmentIds);

    getAdjoinScenarios = scenarioId => this.httpGetRequest(`adjoinScenarios/${scenarioId}`);
}
