import React from "react";
import ReactDOM from "react-dom";

import moment from "moment";

import momentDurationFormatSetup from "moment-duration-format";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import config, { isMsal } from "./config";

import "./styles/styles.css";

import "react-widgets/styles.css";

momentDurationFormatSetup(moment);

let app;
export let msalInstance;

if (isMsal) {
    msalInstance = new PublicClientApplication({
        auth: config.msal
    });

    // Default to using the first account if no account is active on page load
    if (
        !msalInstance.getActiveAccount() &&
		msalInstance.getAllAccounts().length > 0
    ) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback(event => {
        if (
            event.eventType === EventType.LOGIN_SUCCESS &&
			event.payload.account
        ) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    app = (
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    );
} else {
    app = <App />;
}

ReactDOM.render(app, document.getElementById("root"));
