import BaseService from './baseService';

export default class PSOLogsService extends BaseService {
    constructor() {
        super('psoLogs');
    }

    getScenarios = filter => this.httpPostRequest('scenarios', filter);

    loadScenarios = data => this.httpPostRequest('loadScenarios', data);

    getDashboard = data => this.httpPostRequest('getDashboard', data);

    dumpScenarioLogs = filter => this.httpPostRequest('dumpScenarioLogs', filter);
}
