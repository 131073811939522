
import React from 'react';
import { CustomInput } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

const CheckBox = ({ value, changeHandler, isChecked, className, label, disabled }) => {
    const onChange = (e) => {
        e.stopPropagation();
        typeof changeHandler !== 'undefined' && changeHandler({ idx: value, isChecked: !isChecked });
    }

    return <CustomInput disabled={disabled} onClick={e => e.stopPropagation()} style={{ cursor: 'pointer' }} type="checkbox" id={`checkbox-${uniqueId()}-${value}`} checked={isChecked} onChange={onChange} className={className} label={label} />
}

export default CheckBox;