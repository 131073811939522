import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";

const defaultState = {
    runConfigs: [],
    runConfigToEdit: {},
    task: {},
    modalHeader: "Edit run configuration",
    showSpinner: false,

    configModal: {
        open: false,
        title: "",
        scenarios: [],
        scenarioGroups: [],
        isEdit: false,
        partitioning: [],
        form: {
            name: "",
            scenarioNamePrefix: "",
            raMode: null,
            spotWaitTime: null,
            asOfDate: null,
            baseScenarioId: null,
            autoRunIntermediateResultsStage: null,
            drawsNumberFilteringStage: null,
            artitioningModeId: null,
            startDate: null,
            endDate: null,
            lossAdjustmentFactor: null,
            drawsNumberIntermediateResultsStage: null,
            scenariosNumberIntermediateResultsStage: null,
            minVarianceIntermediateResultsStage: 0, //hard coded zero. Task 4676
            scenarioIds: null,
            useSpot: 0,
            isScenarioNamePrefixEditable: false,
        }
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SET_RUN_CONFIGS:
            return dotProp.set(state, "runConfigs", action.data);
        case types.SET_RUN_CONFIG_TO_EDIT:
            return dotProp.set(state, "runConfigToEdit", action.data);
        case types.RA_SET_MODAL_HEADER:
            return dotProp.set(state, "modalHeader", action.data.modalHeader);
        case types.RA_SET_TASK_DATA:
            return dotProp.set(state, "task", action.data);
        case types.RUN_CONFIGS_TOGGLE_SPINNER:
            return dotProp.set(state, "showSpinner", action.data.showSpinner);
        case types.RA_OPEN_CONFIG_MODAL:
            return dotProp.set(state, "configModal", {
                open: true,
                title: action.data.title,
                scenarios: [],
                isEdit: action.data.isEdit,
                form: action.data.form
            });
        case types.RA_CLOSE_CONFIG_MODAL:
            return dotProp.set(state, "configModal", { ...defaultState.configModal });
        case types.RA_EDIT_MODAL_FIELDS:
            return dotProp.merge(state, "configModal.form", action.data);
        case types.RA_SET_SCENARIOS:
            return dotProp.set(state, "configModal.scenarios", action.data);
        case types.RA_SET_SCENARIO_GROUPS:
            return dotProp.set(state, "configModal.scenarioGroups", action.data);
        case types.RA_SET_PARTITIONING:
            return dotProp.set(state, "configModal.partitioning", action.data); 
        case types.RA_RESET:
            return {
                ...defaultState,
            };
        default:
            return state;
    }
};
