import BaseService from './baseService';

/**
 * Service for One-Line diagram
 */
export default class OneLineService extends BaseService {
    constructor() {
        super('one-line');
    }

    /**
    * Gets list of scenarios for specified task
    * @param taskId Task identifier
    */
    getScenarios = taskId => this.httpGetRequest(`${taskId}/scenarios`);

    /**
     * Get areas list
     * @param scenarioId Scenario id
     */
    getAreas = scenarioId => this.httpGetRequest(`${scenarioId}/areas`);

    /**
     * Gets unit types list 
     * @param scenarioId Scenario id 
     */
    getUnitTypes = scenarioId => this.httpGetRequest(`${scenarioId}/unit-types`);

    /**
     * Gets node which stores one of injectors/hubs/nodes 
     * @param scenarioId Scenario id
     * @param areas Areas filter
     * @param unitTypes Unit types filter
     * @param type Enode type (injector, hub, node)
     */
    getNodesLookup = (scenarioId, areas = [], unitTypes = [], type, searchString) =>
        this.httpGetRequest(`${scenarioId}/enodes?${this.generateQueryString({ a: areas, u: unitTypes, type: type, s: searchString })}`);

    /**
     * Gets diagram for nodes with specified depth
     * @param scenarioId Scenario id
     * @param type Enode type (injector, hub, node)
     * @param steps Depth from starting bus
     * @param enode NAME of starting node 
     * @returns List of nodes and branches required to build this chart 
     */
    getDiagram = (scenarioId, type, steps, enode) =>
        this.httpGetRequest(`${scenarioId}/diagram?${this.generateQueryString({ steps, type, enode })}`);

    /**
     * Get the shortest path between 2 BUSES
     * @param scenarioId Scenario id
     * @param fromBus Starting point
     * @param toBus Finish point
     * @returns List of nodes and branches required to build this chart
     */
    getShortestPath = (scenarioId, fromBus, toBus) =>
        this.httpGetRequest(`${scenarioId}/path?${this.generateQueryString({ fromBus, toBus })}`);

    /**
     * Clears cached queries for specified case
     * @param scenarioId Scenario id
     */
    clearCache = scenarioId => this.httpPostRequest(`${scenarioId}/clear`);
}
