import BaseService from './baseService';
import config from '../config'

export default class extends BaseService {
    constructor() {
        super('account', config.AUTH_SERVICE_URL);
    }

    /**
     * Gets user by id(if null = get self info)
     * @param userId User guid
     */
    get = (userId = null) => this.httpGetRequest(userId);

    /**
     * Gets list of users
     * @param filter JSON { userNames: string[], roles: string[] }
     */
    list = filter => this.httpPostRequest('list', filter);

    /**
     * Creates user
     * @param request JSON { email: string, userName: string, password: string, userRole: string }
     */
    create = request => this.httpPostRequest(null, request);

    /**
     * Updates user
     * @param request JSON { email: string, userName: string, password: string }
     */
    update = request => this.httpPutRequest(null, request);

    /**
     * Deletes user
     * @param userId User guid
     */
    delete = userId => this.httpDeleteRequest(userId);

    /**
     * Resets user password
     * @param request JSON { password: string, currentPassword: string, userId: guid}
     */
    resetPassword = request => this.httpPostRequest('resetPassword', request);

    /**
     * Resets all users passwords
     * @param request JSON { password: string }
     */
    resetPasswords = request => this.httpPostRequest('resetPasswords', request);
}
