import types from '../common/actionTypes';


const defaultState = { selectedTabIdx: 1, dashboardTabIdx: 1 };


export default (state = defaultState, action) => {
    switch (action.type) {
        // Set selected tab
        case types.LAYOUT_SWITCH_TAB:
            return {
                ...state,
                selectedTabIdx: action.data.idx
            };
        case types.DASHBOARD_SWITCH_TAB:
            return {
                ...state,
                dashboardTabIdx: action.data.tabIndex
            };
        default:
            return state;
    }
}
