import React from 'react';
import { Col } from 'reactstrap';

const PageNotFound = function() {
    return (
        <div className="app-wrapper bg-white">
            <div className="app-main">
                <div className="app-content p-0">
                    <div className="app-inner-content-layout--main">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                            <div className="bg-composed-wrapper--content">
                                <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                                        <Col lg="6" md="9" className="px-4 px-lg-0 mx-auto text-center text-black">
                                             <h1 className="display-1 mb-3 px-4 font-weight-bold">
                                                 404 Page Not Found
                                             </h1>
                                            <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                                                The page you were looking for doesn't exist
                                            </h3>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;