import * as dotProp from 'dot-prop-immutable';
import types from '../common/actionTypes';

const defaultState = {
    showScenariosSpinner: false,
    showDownloadSpinner: false,
    scenarios: {selected: [], data: []},
    warnings: {},
    logLevels: { selected: [], data: [] },
    compareRules: { selected: [], data: [] },
    eventStatus: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.PSO_LOGS_SET_SCENARIOS:
            // Keep selected scenarios
            return dotProp.merge(state, 'scenarios', action.data);

        case types.PSO_LOGS_SELECT_SCENARIOS:
            return dotProp.set(state, 'scenarios.selected', action.data);
        case types.PSO_LOGS_RESET:
            return {...defaultState};
        case types.PSO_LOGS_SET_WARNINGS:
            return {...state, ...action.data};
        case types.PSO_LOGS_TOGGLE_DOWNLOAD_SPINNER:
            return dotProp.toggle(state, 'showDownloadSpinner');
        case types.PSO_LOGS_TOGGLE_SCENARIOS_SPINNER:
            return dotProp.toggle(state, 'showScenariosSpinner');
        case types.PSO_LOGS_SELECT_LOG_LEVELS:
            return dotProp.set(state, 'logLevels.selected', action.data);
        case types.PSO_LOGS_SELECT_COMPARE_RULES:
            return dotProp.set(state, 'compareRules.selected', action.data);
        case types.PSO_LOGS_RESET_WARNINGS:
            state = dotProp.set(state, 'warnings', defaultState.warnings);
            return dotProp.set(state, 'logLevels', defaultState.warnings);
        case types.PSO_LOGS_EVENT_STATUS:
            return dotProp.set(state, 'eventStatus', action.data);
        default:
            return state;
    }
}