import React, { Fragment } from "react";
import { Button, Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeErrorModal } from "actions/layoutActions";

const ErrorModal = ({ isOpen, errorMessage, detailMessage, close }) => {
    return (
        <Fragment>
            <Modal zIndex={2000} centered isOpen={isOpen} toggle={close}>
                <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                            <FontAwesomeIcon icon={faTimes} className="d-flex align-self-center display-3" />
                        </div>
                    </div>
                    <h4 className="font-weight-bold mt-4">{errorMessage}</h4>
                    {detailMessage && <p className="mb-0 font-size-lg text-muted">{detailMessage}</p>}
                    <div className="pt-4">
                        <Button onClick={close} color="neutral-secondary" className="btn-pill mx-1">
                            <span className="btn-wrapper--label">OK</span>
                        </Button>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    isOpen: state.layout.isErrorModalOpen,
    errorMessage: state.layout.errorModalMessage,
    detailMessage: state.layout.errorModalDetailMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({ close: closeErrorModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
