import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";
import { archivedFilterStates } from "common/enums";

const defaultState = {
    showGridSpinner: false,
    modalHeader: "Edit task",
    gridSource: {
        data: [],
        filter: {
            searchString: null,
            pageNumber: 1,
            pageSize: 30,
            activeStates: [archivedFilterStates.active],
        },
    },
    deleteModal: {
        open: false,
        taskId: null,
        taskName: null,
    },
    archiveModal: {
        open: false,
        taskId: null,
        taskName: null,
        item: {
            archiveResults: 1,
            archiveDays: null,
        }
    },
    project: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.TM_SET_GRID_SOURCE:
            return dotProp.set(state, "gridSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: source.filter,
            }));
        case types.TM_CHANGE_GRID_FILTER:
            return dotProp.merge(state, "gridSource.filter", action.data.filter);
        case types.TM_TOGGLE_GRID_SPINNER:
            return dotProp.set(state, "showGridSpinner", action.data.toggle);
        case types.TM_SET_MODAL_HEADER:
            return dotProp.set(state, "modalHeader", action.data.modalHeader);
        case types.TM_SET_PROJECT_DATA:
            return dotProp.set(state, "project", action.data);
        case types.TM_OPEN_DELETE_MODAL:
            return dotProp.set(state, "deleteModal", { open: true, ...action.data });
        case types.TM_CLOSE_DELETE_MODAL:
            return dotProp.set(state, "deleteModal", defaultState.deleteModal);
        case types.TM_OPEN_ARCHIVE_MODAL:
            return dotProp.set(state, "archiveModal", { open: true, ...action.data, item: defaultState.archiveModal.item });
        case types.TM_CLOSE_ARCHIVE_MODAL:
            return dotProp.set(state, "archiveModal", defaultState.archiveModal);
        case types.TM_ARCHIVE_MODAL_CHANGE_FIELDS:
            return dotProp.merge(state, "archiveModal.item", action.data)
        case types.TM_RESET_GRID_DATA:
            return dotProp.set(state, "gridSource", {
                data: [],
                total: 0,
                filter: {
                    ...state.gridSource.filter,
                    pageNumber: 1,
                    activeStates: [],
                }
            });
        default:
            return state;
    }
};
