import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { store, history, connection } from "./store/configureStore";
import "ui-assets";
import { Route } from "react-router-dom";
import Switch from "components/common/ConnectedSwitch";
import LayoutContainer from "containers/LayoutContainer";

import Login from "components/login/Login";
import { ConnectedRouter } from "connected-react-router";
import { toast, ToastContainer } from "react-toastify";
import AuthRoute from "./components/common/PrivateRoute";
import MomentLocalizer from "react-widgets-moment";
// import { Security, LoginCallback, useOktaAuth } from "@okta/okta-react";
// import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import moment from "moment";
import Localization from "react-widgets/Localization";
import { useMsal } from '@azure/msal-react';

import { isMsal, loginRequest } from './config';

const localizer = new MomentLocalizer(moment);
// const oktaAuth = isVistraOkta ? new OktaAuth(config.okta) : null;

toast.configure();

export default () => {
    const { instance } = useMsal();

    const signOut = () => {
        localStorage.removeItem("token");

        // if (isVistraOkta) {
        //     const { oktaAuth } = useOktaAuth();
        //
        //     oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/' });
        // } else
        if (isMsal) {
            // const { oktaAuth } = useOktaAuth();
            // oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/' });
            instance.logout(loginRequest);

        } else {
            window.location.replace("/login");
        }
    };

    // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    //     history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    // };

    return (
        <Provider store={store}>
            <Localization date={localizer}>
                <ConnectedRouter history={history}>
                    <Suspense fallback={<div>Loading...</div>}>
                        {/*{isVistraOkta && <Security  oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>*/}
                        {/*    <AuthRoute path="/" component={() => <LayoutContainer signOut={signOut} hubConnection={connection} />} />*/}
                        {/*    <Route path="/authorization-code/callback" component={LoginCallback} />*/}
                        {/*</Security>}*/}

                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <AuthRoute path="/" component={() => <LayoutContainer signOut={signOut} hubConnection={connection} />} />
                        </Switch>

                        <ToastContainer enableMultiContainer containerId={"A"} position={toast.POSITION.BOTTOM_CENTER} />
                    </Suspense>
                </ConnectedRouter>
            </Localization>
        </Provider>
    );
};
