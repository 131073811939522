import types from '../common/actionTypes';

const defaultState = {
    showGridSpinner: false,
    gridSource: {
        data: [],
        view: [],
        filter: { searchTerm: null }
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        // Set selected tab
        case types.IM_SET_GRID_SOURCE:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    data: [...action.data.data]
                }
            }
        case types.IM_SET_GRID_VIEW:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    view: [...action.data.view]
                }
            }
        case types.IM_CLEAR_GRID_VIEW:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    view: []
                }
            }
        case types.IM_CHANGE_FILTER_SEARCH_TERM:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    filter: { ...state.gridSource.filter, searchTerm: action.data.searchTerm }
                }
            }
        case types.IM_TOGGLE_GRID_SPINNER:
            return {
                ...state,
                showGridSpinner: action.data.toggle
            }
        default:
            return state;
    }
}
