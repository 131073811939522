import BaseService from './baseService';

export default class ResourceAdequacyService extends BaseService {
    constructor() {
        super('resourceAdequacy');
    }

    getRunConfigs = (taskId) => this.httpGetRequest(`getRunConfigsViewModel/${taskId}`);

    getRunConfig = (runConfigId) => this.httpGetRequest(`getRunConfigEditItem/${runConfigId}`);

    getRunConfigForCopy = (runConfigId) => this.httpGetRequest((`getRunRequest/${runConfigId}`))

    createFullRunConfig = (runConfig) => this.httpPostRequest('createRunConfig', runConfig);

    createRunConfig2Stage = (runConfig) => this.httpPostRequest('createRunConfigIntermediateResultsStageOnly', runConfig);

    editRunConfig = (runConfig) => this.httpPatchRequest('updateRunConfig', runConfig);

    runFilteringStage = (runConfigId) => this.httpPostRequest(`enqueueFilteringStage/${runConfigId}`);

    run2StageAfterFiltering = (runConfigId) => this.httpPostRequest(`enqueueIntermediateResultsStage`, [runConfigId]);

    run2StageOnly = (runConfigId) => this.httpPostRequest(`enqueueIntermediateResultsStageOnly/${runConfigId}`);

    stopConfig = (runConfigId) => this.httpPostRequest(`stopResourceAdequacyRun/${runConfigId}`);

    deleteRunConfig = (runConfigId) => this.httpDeleteRequest(`deleteRunConfig/${runConfigId}`);
}
