import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const ConfirmationDialog = ({ isOpen = false, message = "", successCallback, rejectCallback }) => {
    return (
        <Modal zIndex={2100} isOpen={isOpen} centered>
            <ModalHeader>Confirmation</ModalHeader>
            <ModalBody>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <FontAwesomeIcon size="3x" icon={faQuestionCircle} />
                        </Col>
                        <Col sm={9}>{message}</Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button onClick={successCallback}>Yes</Button>
                <Button onClick={rejectCallback}>No</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmationDialog;
