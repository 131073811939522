import React, { Fragment, Component } from 'react';
import { Button, Row, Col, FormGroup, Input, Label, Form, Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import BlockUi from "react-block-ui";
import { ScaleLoader } from "react-spinners";
import { bindActionCreators, compose } from "redux";
import * as loginActions from "actions/loginActions";
import { connect } from "react-redux";
import { withMsal } from "@azure/msal-react";
import { isMsal, loginRequest } from '../../config';

class Login extends Component {
    state = { userName: null, password: null, errorMsg: null, isLoading: false };

    constructor(props) {
        super(props);
    }

    toggleSpinner = spin => {
        this.setState({ isLoading: spin });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    componentDidMount() {
        if (isMsal) {
            const { msalContext, history } = this.props;

            if (msalContext.accounts.length > 0) {
                history.replace('/');
            } else {
                msalContext.instance.loginRedirect(loginRequest);
            }
        }
    }

    onLogin = e => {
        const { userName, password } = this.state;
        const { login, history, location } = this.props;

        this.setState({ errorMsg: null });
        login({
            userName,
            password,
            spinnerToggle: this.toggleSpinner,
            successCallback: r => {
                localStorage.setItem("token", r.token);
                if(this.props.location.state) {
                    const { pathname, search } = this.props.location.state.from
                    window.location.replace(`${pathname}${search}`);
                } else {
                    window.location.replace('/');
                }
            },
            errorCallback: r => {
                this.setState({ errorMsg: r.response.data });
            }
        });
    }

    onKeyPress = e => {
        if (e.key === 'Enter') {
            this.onLogin();
        }
    }

    render() {
        const { userName, password, isLoading, errorMsg } = this.state;

        return (
            <Fragment>
                <div className="app-wrapper min-vh-100" onKeyPress={this.onKeyPress} tabIndex="0">
                    <div className="app-main min-vh-100">
                        <div className="app-content p-0">
                            <BlockUi tag="div" className="app-content--inner d-flex align-items-center"
                                blocking={isLoading}
                                loader={<ScaleLoader color={'var(--first)'} loading={isLoading} />}>
                                <Row className="justify-content-md-center w-100">
                                    <Col sm="4">
                                        <Form >
                                            <FormGroup>
                                                <Label htmlFor="form-user-name">Login</Label>
                                                <Input placeholder="Enter your login" type="text" id="form-user-name"
                                                    name="userName"
                                                    value={userName || ''} onChange={this.handleChange} />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label htmlFor="form-password">Password</Label>
                                                <Input placeholder="Enter your password" type="password"
                                                    id="form-password" name="password"
                                                    value={password || ''} onChange={this.handleChange} />
                                            </FormGroup>
                                            <Button size="lg" block={true} color="second" autoFocus onClick={this.onLogin}>
                                                Login
                                            </Button>
                                            {errorMsg && <Alert className="mt-3" color="danger">{errorMsg}</Alert>}
                                        </Form>
                                    </Col>
                                </Row>
                            </BlockUi>
                        </div>
                    </div>
                </div>
            </Fragment>);
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...loginActions }, dispatch);

export default compose(
    withMsal,
    withRouter,
    connect(null, mapDispatchToProps),
)(Login)
