import BaseService from './baseService';

export default class EnelytixBillingService extends BaseService {
    constructor() {
        super('enelytixBilling');
    }

    getEnelytixBilling = filter => this.httpPostRequest('list', filter);

    downloadEnelytixBillingSearchResult = filter => this.httpPostRequest('download', filter);

}
