import types from "common/actionTypes";
import dotProp from "dot-prop-immutable";
import { scenarioGroupRowStatuses, scenarioGroupFilesTabs } from "common/enums";

const defaultState = {
    showGridSpinner: false,
    modalHeader: "Edit group",
    selectedIds: [],
    selectedGroup: {},
    gridSource: {
        data: [],
        filter: {
            searchString: null,
            pageNumber: 1,
            pageSize: 50,
            includeStatus: true
        },
    },
    linksModal: {
        open: false,
        locked: false,
        group: {},
        source: {
            data: [],
            total: 0
        }
    },
    inputsModal: {
        open: false,
        locked: false,
        group: {},
        files: []
    },
    resultsModal: {
        open: false,
        locked: false,
        group: {},
        files: []
    },
    task: {},
    externalFunctions: [],
    functionModal: {
        open: false,
        locked: false,
        source: {}
    },
    filesModal: {
        open: false,
        locked: false,
        selectedTab: scenarioGroupFilesTabs.settings,
        specification: null,
        source: null,
        group: null,
        filters: {},
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SG_SET_GRID_SOURCE:
            return dotProp.set(state, "gridSource", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: source.filter,
            }));
        case types.SG_CHANGE_GRID_FILTER:
            return dotProp.merge(state, "gridSource.filter", action.data.filter);
        case types.SG_TOGGLE_GRID_SPINNER:
            return dotProp.set(state, "showGridSpinner", action.data.toggle);
        case types.SG_SET_MODAL_HEADER:
            return dotProp.set(state, "modalHeader", action.data.modalHeader);
        case types.SG_SET_TASK_DATA:
            return dotProp.set(state, "task", action.data);
        case types.SG_CHANGE_SELECTED:
            return dotProp.set(state, "selectedIds", action.data.selectedIds);
        case types.SG_TOGGLE_LINKS_MODAL_OPEN:
            return dotProp.set(state, "linksModal.open", action.data.toggle);
        case types.SG_TOGGLE_LINKS_MODAL_LOCKED:
            return dotProp.set(state, "linksModal.locked", action.data.toggle);
        case types.SG_SET_LINKS_MODAL_SOURCE:
            return dotProp.set(state, "linksModal.source", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: source.filter,
            }));
        case types.SG_TOGGLE_INPUTS_MODAL_OPEN:
            return dotProp.set(state, "inputsModal.open", action.data.toggle);
        case types.SG_TOGGLE_INPUTS_MODAL_LOCKED:
            return dotProp.set(state, "inputsModal.locked", action.data.toggle);
        case types.SG_SET_INPUTS_MODAL_SOURCE:
            return dotProp.set(state, "inputsModal.files", action.data);
        case types.SG_TOGGLE_RESULTS_MODAL_OPEN:
            return dotProp.set(state, "resultsModal.open", action.data.toggle);
        case types.SG_TOGGLE_RESULTS_MODAL_LOCKED:
            return dotProp.set(state, "resultsModal.locked", action.data.toggle);
        case types.SG_SET_RESULTS_MODAL_SOURCE:
            return dotProp.set(state, "resultsModal.files", action.data);
        case types.SG_SET_SELECTED_GROUP:
            return dotProp.set(state, "selectedGroup", action.data);
        case types.SG_SET_EXTERNAL_FUNCTIONS:
            return dotProp.set(state, "externalFunctions", action.data);

        case types.SG_RESET_FILES_MODAL:
            return dotProp.set(state, "filesModal", defaultState.filesModal);
        case types.SG_SET_FILES_MODAL_SOURCE:
            return dotProp.merge(state, "filesModal.source", action.data);
        case types.SG_TOGGLE_FILES_MODAL_OPEN:
            return dotProp.set(state, "filesModal.open", action.data.toggle);
        case types.SG_TOGGLE_FILES_MODAL_LOCKED:
            return dotProp.set(state, "filesModal.locked", action.data.toggle);
        case types.SG_SET_FILES_SPECIFICATION:
            return dotProp.set(state, "filesModal.specification", action.data.specs);
        case types.SG_SET_FILES_MODAL_TAB:
            return dotProp.set(state, "filesModal.selectedTab", action.data.tab);
        case types.SG_SET_FILES_MODAL_GROUP:
            return dotProp.set(state, "filesModal.group", action.data.group);
        case types.SG_CHANGE_ROW:
            {
                const { file, index, data } = action.data;

                const rowPath = `filesModal.source.${file}.${index}`;
                const type = dotProp.get(state, `${rowPath}.type`, scenarioGroupRowStatuses.unchanged);
                // don't change "add" status
                if (type === scenarioGroupRowStatuses.unchanged) {
                    state = dotProp.set(state, `${rowPath}.type`, scenarioGroupRowStatuses.changed);
                }
                return dotProp.merge(state, `${rowPath}.data`, data);
            }

        case types.SG_DELETE_ROW:
            {
                const { file, index } = action.data;

                return dotProp.set(state, `filesModal.source.${file}.${index}.type`, scenarioGroupRowStatuses.hidden);
            }

        case types.SG_SET_FILES_MODAL_FILTER:
            return dotProp.set(state, `filesModal.filters.${action.data.file}`, action.data.filter);

        case types.SG_UPDATE_FILES_MODAL_FILTER:
            return dotProp.merge(state, `filesModal.filters.${action.data.file}`, action.data.filter);

        case types.SG_ADD_ROW:
            {
                const { file, data = {} } = action.data;

                const fileSource = dotProp.get(state, `filesModal.source.${file}`);
                return dotProp.set(state, `filesModal.source.${file}`, [...fileSource, { type: scenarioGroupRowStatuses.new, index: fileSource.length, data, errors: {} }]);
            }

        case types.SG_SET_FILES_MODAL_ERRORS:
            {
                for (const { file, index, errors }
                    of action.data) {
                    state = dotProp.merge(state, `filesModal.source.${file}.${index}.errors`, errors);
                }
                return state;
            }

        case types.SG_RESET_FILES_MODAL_ERRORS:
            {
                const files = state.filesModal.source;

                for (const file of Object.keys(files)) {
                    for (let index = 0; index < files[file].length; index++) {
                        state = dotProp.set(state, `filesModal.source.${file}.${index}.errors`, {});
                    }
                }
                return state;
            }

        case types.SG_TOGGLE_FUNCTION_MODAL_OPEN:
            return dotProp.set(state, "functionModal.open", action.data.toggle);
        case types.SG_SET_FUNCTION_MODAL_SOURCE:
            return dotProp.set(state, "functionModal.source", action.data);

        case types.SG_RESET:
            return {...defaultState };
        default:
            return state;
    }
};