import types from 'common/actionTypes';
import dotProp from 'dot-prop-immutable';


const defaultState = {
    dashboardsData: [],
    selectDataValue: '',
    isSidebarOpen: true,
    openNodes: {},
    task: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.DASHBOARD_SET_DATA:
            return dotProp.set(state, 'dashboardsData', action.data);
        case types.DASHBOARD_SET_SELECT_DATA:
            return dotProp.set(state, 'selectDataValue', action.data);
        case types.DASHBOARD_TOGGLE_SIDEBAR:
            return dotProp.set(state, 'isSidebarOpen', !state.isSidebarOpen);
        case types.DASHBOARD_TOGGLE_NODE:
            return dotProp.toggle(state, `openNodes.${action.nodeId}`);
        case types.DASHBOARD_SET_TASK:
            return dotProp.set(state, 'task', action.data);
        case types.DASHBOARD_MGMT_RESET_SCREEN:
            return { ...defaultState };
        default:
            return state;
    }
}