import types from "common/actionTypes";
import { levels, detailTypes, partitionLevels } from "common/enums";
import dotProp from "dot-prop-immutable";

const defaultFilter = {
    pageNumber: 1,
    pageSize: 10,
};

const defaultState = {
    masterSelected: [],
    detailSelected: [],
    masterLevel: levels.cube,
    detailLevel: detailTypes.linked,
    partitionLevel: partitionLevels.reports,
    scenarios: [],
    cubes: [],
    scopes: [],
    hasPendingChanges: false,

    // master sources
    masterSource: {
        data: [],
        total: 0,
        filter: {
            ...defaultFilter,
        },
    },

    // detail sources
    detailSource: {
        data: [],
        total: 0,
        filter: {
            ...defaultFilter,
        },
    },

    // partition sources
    segmentSource: {
        data: [],
        total: 0,
        filter: {
            ...defaultFilter,
        },
    },

    // reports source
    reportSource: {
        data: [],
    },

    // spinners
    generalSpinner: false,
    masterSpinner: false,
    detailSpinner: false,
    partitionSpinner: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        // filters
        case types.CM_RESET_MASTER_SOURCE:
            return {
                ...state,
                hasPendingChanges: false,
                detailSelected: [],
                masterSelected: [],

                // reset all related lists
                masterSource: {
                    ...state.masterSource,
                    data: [],
                    total: 0,
                },
                detailSource: {
                    ...state.detailSource,
                    data: [],
                    total: 0,
                },
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };

        case types.CM_SET_SEGMENT_FILTER:
            return dotProp.merge(state, "segmentSource.filter", action.data);
        case types.CM_SET_MASTER_FILTER:
            return dotProp.merge(state, "masterSource.filter", action.data);
        case types.CM_SET_DETAIL_FILTER:
            return dotProp.merge(state, "detailSource.filter", action.data);
        // level switches
        case types.CM_SET_MASTER_LEVEL:
            return {
                ...state,
                masterLevel: action.data.level,
                hasPendingChanges: false,
                detailSelected: [],
                masterSelected: [],

                // reset all related lists
                masterSource: {
                    ...state.masterSource,
                    data: [],
                    total: 0,
                },
                detailSource: {
                    ...state.detailSource,
                    data: [],
                    total: 0,
                },
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };

        case types.CM_SET_DETAIL_LEVEL:
            return {
                ...state,
                detailLevel: action.data.level,
                detailSelected: [],
                hasPendingChanges: false,

                // reset all related lists
                detailSource: {
                    ...state.detailSource,
                    data: [],
                    total: 0,
                },
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };
        case types.CM_SET_PARTITIONING_LEVEL:
            return {
                ...state,
                partitionLevel: action.data.level,

                // reset all related lists
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };
        // data sources
        case types.CM_SET_MASTER_SOURCE:
            return {
                ...state,
                masterSource: {
                    ...state.masterSource,
                    data: action.data.data,
                    total: action.data.total,
                    filter: {
                        ...state.masterSource.filter,
                        ...action.data.filter,
                    },
                },
            };
        case types.CM_SET_DETAIL_SOURCE:
            return {
                ...state,
                detailSource: {
                    ...state.detailSource,
                    data: action.data.data,
                    total: action.data.total,
                    filter: {
                        ...state.detailSource.filter,
                        ...action.data.filter,
                    },
                },
            };
        case types.CM_SET_REPORTS_SOURCE:
            return {
                ...state,
                reportSource: {
                    ...state.reportSource,
                    data: action.data.data,
                },
            };
        case types.CM_SET_SEGMENT_SOURCE:
            return {
                ...state,
                segmentSource: {
                    ...state.segmentSource,
                    data: action.data.data,
                    total: action.data.total,
                    filter: {
                        ...state.segmentSource.filter,
                        ...action.data.filter,
                    },
                },
            };
        case types.CM_SET_MASTER_SELECTED:
            return {
                ...state,
                masterSelected: action.data.idx !== null ? [action.data.idx] : [],
            };
        case types.CM_SET_DETAIL_SELECTED:
            return {
                ...state,
                detailSelected: action.data.idx !== null ? [action.data.idx] : [],
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };
        case types.CM_SET_DETAIL_CHECKED:
            return {
                ...state,
                detailSelected: action.data.checkedIds,
            };
        case types.CM_SET_MASTER_CHECKED:
            return {
                ...state,
                masterSelected: action.data.checkedIds,
            };
        case types.CHANGE_SCREEN_EDITED_STATE:
            return dotProp.set(state, "hasPendingChanges", !action.data.isClean);
        case types.CM_MODIFY_REPORTS_SOURCE:
            return {
                ...state,
                hasPendingChanges: true,
                reportSource: {
                    ...state.reportSource,
                    data: action.data.reports,
                },
            };
        case types.CM_DELETE_REPORTS:
            return {
                ...state,
                detailSelected: [],
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };
        case types.CM_RESET_SEGMENT_SOURCE:
            return {
                ...state,
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };
        case types.CM_RESET_DETAIL_SOURCE:
            return {
                ...state,
                detailSelected: [],
                detailSource: {
                    ...state.detailSource,
                    data: [],
                    total: 0,
                },
                segmentSource: {
                    ...state.segmentSource,
                    data: [],
                    total: 0,
                },
                reportSource: {
                    ...state.reportSource,
                    data: [],
                },
            };

        case types.CM_TOGGLE_MASTER_SPINNER:
            return dotProp.set(state, "masterSpinner", action.data.showSpinner);
        case types.CM_TOGGLE_DETAIL_SPINNER:
            return dotProp.set(state, "detailSpinner", action.data.showSpinner);
        case types.CM_TOGGLE_PARTITION_SPINNER:
            return dotProp.set(state, "partitionSpinner", action.data.showSpinner);
        case types.CM_SET_REPORTS_EDITABLE:
            return {
                ...state,
                partitionSpinner: action.data.showSpinner,
                reportsEditable: action.data.editable,
            };
        case types.CM_SET_LOOKUPS:
            return   { ...state, ...action.data};
        case types.CM_SET_DEFAULT_REPORTS_FLAG: 
            return dotProp.set(state, "task.hasDefaultReports", action.data.hasReports);
        default:
            return state;
    }
};
