import types from 'common/actionTypes';
import dotProp from 'dot-prop-immutable';


const defaultState = {
    data: null,
    showSettings: true,
    isFiltersModalOpen: false,
    isAreasEditModalOpen: false,
    chart: {
        pointBackgroundColor: {},
    },
    filter: {
        caseNames: null,

        startDate: null,
        endDate: null,

        // value must be adjacency list
        areas: null,

        fuelTypes: null,
        scenarios: null,
        unitTypes: null,
        areasAgg: null
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {

        case types.DASHBOARD_UPDATE_FILTER:
            const { filter } = action.data;

            for(let [name, value] of Object.entries(filter)) {
                const update = value !== null ? dotProp.merge : dotProp.set;
                state = update(state, `filter.${name}`, value);
            }
            return state;

        case types.DASHBOARD_SET_RESULT:
            return dotProp.set(state, 'data', action.data.result);

        case types.DASHBOARD_CHANGE_UNIT_TYPE_MODE:
            for(let [index, { key }] of state.filter.unitTypes.items.entries()) {
                if (action.data.keys.indexOf(key) !== -1) {
                    state = dotProp.set(state, `filter.unitTypes.items.${index}.mode`, action.data.mode)
                }
            }
            return state;

        case types.DASHBOARD_RESET_RESULT:
            return dotProp.set(state, 'data', defaultState.data);

        case types.DASHBOARD_SET_POINT_COLOR:
            return dotProp.set(state, 'chart.pointBackgroundColor', action.data.pointBackgroundColor);

        case types.DASHBOARD_TOGGLE_FILTERS_MODAL:
            return dotProp.toggle(state, 'isFiltersModalOpen')

        case types.DASHBOARD_TOGGLE_AREAS_EDIT_MODAL:
            return dotProp.toggle(state, 'isAreasEditModalOpen')

        default:
            return state;
    }
}