import BaseService from "./baseService";

export default class extends BaseService {
    constructor() {
        super('api/oti');
    }

    fetchOTIConfigurations = filter => this.httpPostRequest('getConfigurations', filter);

    fetchOTIFileHistories = filter => this.httpPostRequest('getOTIFileHistories', filter);

    fetchOTIHistories = filter => this.httpPostRequest('getOTIHistories', filter);

    fetchScenarios = filter => this.httpPostRequest('getScenarios', filter);

    fetchDataTypes = () => this.httpGetRequest('getDataTypes');

    fetchDataTypeFilters = filter => this.httpPostRequest('getFiltersValues', filter);

    saveConfiguration = item => this.httpPostRequest('saveOTIConfiguration', item);

    saveFileHistories = data => this.httpPostRequest('saveOTIFileHistories', data)

    runOTI = configurationId => this.httpPostRequest(`runOTI/${configurationId}`)

    restartFileHistories = request => this.httpPostRequest('restartApplies', request);

    restartQueries = request => this.httpPostRequest('restartQueries', request);

    saveUserFilters = request => this.httpPostRequest('saveDefaultFilters', request)

    fetchUserFilters = request => this.httpPostRequest('getDefaultFilters', request)

    downloadResults = request => this.httpPostRequest('download', request);

    deleteConfigurations = request => this.httpDeleteRequest('deleteOTIConfiguration', request)
}
