import { combineReducers } from "redux";
import cubes from "./cubeReducer";
import layout from "./layoutReducer";
import cubeGroups from "./cubeGroupsReducer";
import staticReports from "./staticReportsReducer";
import psoResults from "./psoResultsReducer";
import navigation from "./navigationReducer";
import dashboard from "./dashboardReducer";
import map from "./mapReducer";
import comparer from "./comparerReducer";
import ThemeOptions from "./ThemeOptions";
import instanceMgr from "./instanceManagerReducer";
import accountMgr from "./accountManagerReducer";
import projectMgr from "./projectMgmtReducer";
import taskMgr from "./taskMgmtReducer";
import scenarioMgr from "./scenarioMgmtReducer";
import scenarioStatus from "./scenarioStatusReducer";
import reportingRoot from "./reportingReducer";
import enelytixBillingRoot from "./enelytixBillingReducer";
import logs from "./psoLogsReducer";
import scenarioGroups from "./scenarioGroupReducer";
import tags from "./tagReducer";
import otiManager from "./otiReducer";
import probes from "./probeReducer";
import resourceAdequacy from "./resourceAdequacyReducer";
import dashboardMgmt from "./dashboardMgmtReducer";
import oneLineDiagram from "./oneLineDiagramReducer";
import { connectRouter } from "connected-react-router";

export default history =>
    combineReducers({
        cubes,
        layout,
        cubeGroups,
        staticReports,
        navigation,
        psoResults,
        dashboard,
        map,
        comparer,
        ThemeOptions,
        router: connectRouter(history),
        instanceMgr,
        accountMgr,
        projectMgr,
        taskMgr,
        scenarioMgr,
        scenarioStatus,
        reportingRoot,
        enelytixBillingRoot,
        logs,
        scenarioGroups,
        tags,
        probes,
        otiManager,
        resourceAdequacy,
        dashboardMgmt,
        oneLineDiagram,
    });
