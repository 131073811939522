import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    UncontrolledTooltip,
    NavLink,
    Button,
    BreadcrumbItem,
    Breadcrumb,
    UncontrolledPopover,
    Badge,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import { faDesktop, faUsers, faTasks, faSignOutAlt, faUnlock, faBolt, faSearch, faCoins, faInfo } from "@fortawesome/free-solid-svg-icons";
import * as logos from "images/logos";
import { NavLink as RRNavLink } from "react-router-dom";
import configuration, { environment } from '../../config';
import { parseNotifications } from "common/helpers";

const Header = ({ actions, data, navigation, references }) => {
    const { changePassword, signOut, search, fetchFeedData, setNewPostAvailable } = actions;
    const { currentUser, eventsCount, featuresConfiguration, newPostAvailable } = data;
    const { eventsList } = references;

    const [keyword, changeKeyword] = useState('');

    const onOpenHangfireDashboard = () => {
        window.open(`${configuration.API_SERVER}/hangfire?access_token=${localStorage.getItem('token')}`, '_blank');
    }

    useEffect(() => {
        if (currentUser.userName) {
            fetchFeedData(
                currentUser.userName,
                (data) => setNewPostAvailable(data.newPost),
                (error) => parseNotifications({ errors: [error.message] })
            );
        }
    }, [currentUser]);

    return (
        <Fragment>
            <div className="app-header app-header--shadow app-header--opacity-bg">
                <div className="header-nav-logo">
                    <div className="nav-logo">
                        <RRNavLink to="/projects">
                            <img alt="Site logo" src={logos.enelytix} style={{ width: "200px" }} />
                        </RRNavLink>
                    </div>
                </div>
                <div className="app-header--pane">
                    {navigation && (
                        <Breadcrumb className="h4" listClassName="bg-transparent mb-1">
                            {navigation.map((nav, idx) => (
                                <BreadcrumbItem tag={RRNavLink} key={idx} active={nav.active ?? false} to={nav.url}>
                                    {nav.text}
                                </BreadcrumbItem>
                            ))}
                        </Breadcrumb>
                    )}
                </div>
                <div className="app-header--pane">
                    <form onSubmit={e => { e.preventDefault(); search(keyword) }}>
                        <div className="search-wrapper">
                            <input className="form-control form-control-sm rounded-pill" style={{ paddingLeft: '0.65rem', paddingRight: 'calc(32px + 0.52rem)' }}
                                placeholder="Case Id Search..." type="search" onChange={e => changeKeyword(e.target.value)} value={keyword} />
                            <span className="icon-wrapper text-black" style={{ left: 'auto', marginRight: '-16px', right: '1.3rem' }}>
                                <FontAwesomeIcon icon={faSearch} style={{ cursor: 'pointer' }} onClick={() => search(keyword)} />
                            </span>
                        </div>
                    </form>
                    <Button color={eventsCount > 0 ? "link-success" : "link"} onClick={() => eventsList?.current.open()} id="layout-events-button">
                        <span className="btn-wrapper--icon p-1">
                            <FontAwesomeIcon icon={faBolt} />
                        </span>
                        <UncontrolledTooltip target="layout-events-button">Events</UncontrolledTooltip>
                        <Badge><b>{eventsCount}</b></Badge>
                    </Button>
                    {currentUser.isSuperAdmin &&
                        <NavLink  >
                            <Button color="link" className="p-1" id="layout-hangfire-button" onClick={onOpenHangfireDashboard}>
                                <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={faTasks} />
                                </span>
                            </Button>
                            <UncontrolledTooltip target="layout-hangfire-button">Background jobs</UncontrolledTooltip>
                        </NavLink>}
                    <NavLink tag={RRNavLink} to={`/accounts`} >
                        <Button color="link" className="p-1" id="layout-accounts-button">
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={faUsers} />
                            </span>
                        </Button>
                        <UncontrolledTooltip target="layout-accounts-button">Accounts</UncontrolledTooltip>
                    </NavLink>
                    <NavLink tag={RRNavLink} to={`/instances`} >
                        <Button color="link" className="p-1" id="layout-instances-button">
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={faDesktop} />
                            </span>
                        </Button>
                        <UncontrolledTooltip target="layout-instances-button">Instance manager</UncontrolledTooltip>
                    </NavLink>

                    {featuresConfiguration.billingEnabled &&
                        <NavLink tag={RRNavLink} to={`/billing`} >
                            <Button color="link" className="p-1" id="enelytix-billing-button">
                                <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={faCoins} />
                                </span>
                            </Button>
                            <UncontrolledTooltip target="enelytix-billing-button">Usage Report</UncontrolledTooltip>
                        </NavLink>}

                    <NavLink tag={RRNavLink} to={`/documentation`} >
                        <Button color={newPostAvailable ? "link-success" : "link"} className="p-1" id="documentation">
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={faInfo} />
                            </span>
                        </Button>
                        <UncontrolledTooltip target="documentation">Documentation</UncontrolledTooltip>
                    </NavLink>

                    <Button color="link" id="layout-user-box">
                        <span className="btn-wrapper--label text-black"> {currentUser.userName}</span>
                    </Button>

                    <UncontrolledPopover placement="bottom" trigger="legacy" target="layout-user-box"
                        className="popover-custom-wrapper popover-custom-lg">
                        <ListGroup flush className="text-left bg-transparent">
                            <ListGroupItem className="rounded-top">
                                <span className="h5 text-primary">Account</span>
                            </ListGroupItem>
                            <ListGroupItem>
                                <div className="d-flex flex-column">
                                    <div>
                                        <b>User : </b> <span> {currentUser.userName}</span>
                                    </div>
                                    <div>
                                        <b>Roles : </b> <span className="">{currentUser.userRoles?.join(", ")}</span>
                                    </div>
                                    {featuresConfiguration.allowChangePassword &&
                                        <Button onClick={() => typeof changePassword === "function" && changePassword()}
                                            color="link"
                                            className="m-2 p-1 btn-icon btn-animated-icon-sm">
                                            <span className="btn-wrapper--icon">
                                                <FontAwesomeIcon icon={faUnlock} />
                                            </span>
                                            <span className="btn-wrapper--label"> Change password </span>
                                        </Button>}
                                </div>
                            </ListGroupItem>
                            <ListGroupItem className="rounded-bottom p-2 text-center">
                                <Button onClick={() => typeof signOut === "function" && signOut()} color="link-primary"
                                    className="m-2 p-1 btn-icon btn-animated-icon-sm">
                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                    </span>
                                    <span className="btn-wrapper--label"> Sign out </span>
                                </Button>

                            </ListGroupItem>
                        </ListGroup>
                    </UncontrolledPopover>
                    <div className="header-nav-logo ml-5">
                        <img src={process.env.LOGO_URL || logos.enelytix} style={{ width: "120px" }} alt="company" />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
