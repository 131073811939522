import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import { useOktaAuth, SecureRoute } from '@okta/okta-react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { isMsal, loginRequest } from '../../config';

const AuthRoute = props => {
    const token = localStorage.getItem("token");

    if (!token) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
    }

    return <Route {...props} />;
};

const OktaAuthRoute = props => {
    const token = localStorage.getItem("token");
    const { authState } = useOktaAuth();

    useEffect(() => {
        if (authState && authState.isAuthenticated && token !== authState.accessToken) {
            localStorage.setItem('token', authState.idToken);
        }
    }, [authState])

    return <SecureRoute {...props} />;
}

const MsalAuthRoute = props => {
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ ...loginRequest, forceRefresh: true }}
            errorComponent={({ error }) => <p>An Error Occurred: {error}</p>}
            loadingComponent={() => <p>Authentication in progress...</p>}
        >
            <Route {...props} />
        </MsalAuthenticationTemplate>
    )
}

export default isMsal ? MsalAuthRoute :  AuthRoute;

