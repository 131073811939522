import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super("settings");
    }

    modifySettings = settings => {
        return this.httpPostRequest(null, { settings: { MaxStaticReportMachines: settings.maxStaticReportMachines} });
    }

    getSettings = () => {
        return this.httpGetRequest();
    }

    getFeaturesConfiguration = () =>
        this.httpGetRequest(`featuresConfiguration`);
}