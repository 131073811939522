import BaseService from './baseService';
import axios from 'axios';


export default class DashboardService extends BaseService {
    constructor() {
        super('dashboard');
    }

    getScenarios = filter => this.httpPostRequest('scenarios', filter);

    loadScenarios = data => this.httpPostRequest('loadScenarios', data);

    getDashboard = data => this.httpPostRequest('getDashboard', data);

    getMapSettings = taskId => this.httpGetRequest(`getMapSettings/${taskId}`);

    getS3File = url => axios.get(url);

    getComparedFiles = data => this.httpPostRequest('getComparisonFilesList', data);

    getComparedRows = data => this.httpPostRequest('fileComparison', data);

    dumpFileComparison = filter => this.httpPostRequest('dumpFileComparison', filter);
}
