import React from 'react';

import {Modal} from "reactstrap";
import Draggable from 'react-draggable';


class DraggableModal extends Modal {
    renderModalDialog() {
        const {handle} = this.props;
        return (
            <Draggable handle={handle} scale={0.8}>
                {super.renderModalDialog()}
            </Draggable>
        )
    };
}

export default DraggableModal;
