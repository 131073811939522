import React, { useState } from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Input } from 'reactstrap';
import filter from 'lodash/filter';
import CheckBox from './CheckBox';
import cx from 'classnames';
import PerfectScrollbar from "react-perfect-scrollbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ConditionalWrapper from "./ConditionalWrapper";

const DefaultMultiSelectItem = ({ idx, displayName, isSelected, onSelect, disabled }) => {
    // https://github.com/reactstrap/reactstrap/issues/632
    return (<DropdownItem onClick={() => onSelect({ isChecked: !isSelected, idx })} toggle={false} disabled={disabled}>
        <Row className='flex-nowrap'>
            <Col sm={2}><CheckBox value={idx} isChecked={isSelected} /></Col>
            <Col sm={10}><small>{displayName}</small></Col>
        </Row>
    </DropdownItem>)
}

const Search = ({ onChange, size, value }) => {
    return (
        <DropdownItem disabled>
            <Row>
                <Col>
                    <Input bsSize={size} onChange={e => onChange(e.target.value)} value={value} placeholder="Search..." autoFocus style={{pointerEvents: 'auto'}}/>
                </Col>
            </Row>
        </DropdownItem>
    )
}

export default function({
    selectedIds = [],
    isDisabled,
    className,
    direction,
    size,
    expandable,
    source,
    onChange,
    label,
    searchable = true,
    MultiSelectItem = DefaultMultiSelectItem
}) {
    const [expanded, expand] = useState(false);
    const [isOpen, showList] = useState(false);
    const [keyword, setKeyword] = useState('');

    const filteredSource = (source || []).filter(x => !keyword || x.displayName.includes(keyword));
    const activeIds = filteredSource.filter(x => !x.disabled).map(x => x.idx);

    const onSelect = ({ idx, isChecked }) => {
        if (typeof onChange === 'function') {
            if (idx === -1) {
                onChange({ value: isChecked ? selectedIds.concat(activeIds.filter(id => !selectedIds.includes(id))) : selectedIds.filter(id => !activeIds.includes(id)) })
            } else {
                onChange({ value: isChecked ? selectedIds.concat(idx) : filter(selectedIds, s => s !== idx) });
            }
        }
    }

    const onSearch = keyword => setKeyword(keyword);

    const onExpand = () => expand(!expanded);

    const toggle = () => {
        showList(!isOpen);
        setKeyword('');
    }

    const onClick = e => {
        // in order to prevent event bubbling.
        e.stopPropagation();
    }

    const getDisplay = () => {
        if (selectedIds == null || selectedIds.length < 1) {
            return 'Not selected';
        }

        return filter(source, s => selectedIds.indexOf(s.idx) !== -1).map(s => s.displayName).join(', ');
    }

    const showScroll = filteredSource?.length > 3;
    const showArrow = expandable && filteredSource?.length > 3;

    return (
        <Dropdown isOpen={isOpen} direction={direction} toggle={toggle} onClick={onClick} disabled={isDisabled} className={cx(className, { 'overflow-hidden': !isOpen })} size={size}>
            <DropdownToggle style={{ minWidth: "100%" }} tag={label ? 'div' : ''} color="light" className={cx("truncated-line", {'disabled': isDisabled})}>
                {label ? (
                    <span>{label}</span>
                ) : (
                    getDisplay()
                )}
            </DropdownToggle>
            <DropdownMenu right className={cx('overflow-hidden', showScroll ? 'dropdown-scrollable' : 'dropdown-no-scroll', {'pb-1': expandable})} style={{ maxHeight: expanded ? 465 : 255, height: expanded ? 465 : 255, overflow: 'hidden' }}>
                <ConditionalWrapper
                    condition={showScroll}
                    wrapper={children => <PerfectScrollbar>{children}</PerfectScrollbar>}>
                        {searchable && (
                            <Search onChange={onSearch} size={size} value={keyword}/>
                        )}

                        <MultiSelectItem idx={-1} displayName={"Select all"} isSelected={selectedIds.length && activeIds.every(id => selectedIds.includes(id))} onSelect={onSelect} disabled={!filteredSource.length}/>
                        <DropdownItem divider />
                        {filteredSource.map(s => <MultiSelectItem key={s.idx} idx={s.idx} displayName={s.displayName} isSelected={selectedIds && selectedIds.indexOf(s.idx) !== -1} onSelect={onSelect} {...s} />)}
                    </ConditionalWrapper>
                    {showArrow &&
                        <div className='d-flex justify-content-center bg-white sticky-bottom' style={{'borderTop': '1px solid rgba(0, 0, 0, 0.1)'}}>
                            <FontAwesomeIcon onClick={onExpand} icon={expanded ? faAngleUp : faAngleDown} size='xs' style={{'cursor': 'pointer'}} />
                        </div>}
            </DropdownMenu>
        </Dropdown>
    )
}
