import types from "common/actionTypes";
import {
	fetch,
	fetchParallel,
	mapLookup,
	mapMultiselectLookup,
} from "common/helpers";
import { accountSvc as service, projectSvc, lookupSvc, userSettingsSvc } from "services/serviceStorage";
import some from 'lodash/some';

export const setGridSource = (dataSource) => ({
	type: types.AM_SET_GRID_SOURCE,
	data: { ...dataSource },
});
export const setGridView = (view) => ({
	type: types.AM_SET_GRID_VIEW,
	data: { view },
});
export const clearGridView = () => ({ type: types.AM_CLEAR_GRID_VIEW });
export const changeSearchTerm = (searchTerm) => ({
	type: types.AM_CHANGE_FILTER_SEARCH_TERM,
	data: { searchTerm },
});
export const toggleGridSpinner = (toggle) => ({
	type: types.AM_TOGGLE_GRID_SPINNER,
	data: { toggle },
});

export const fetchAccounts = function ({ spinnerToggle, successCallback }) {
	return fetch({
		spinnerToggle,
		callback: r =>
			successCallback({
				data: r.map(i => ({ ...i, key: i.userId, userRole: i.userRoles[0] })),
				total: r.length,
			}),
		request: () => service.list({}),
	});
};

export const createAccount = function ({
	account,
	spinnerToggle,
	validationCallback,
	successCallback,
	errorCallback
}) {
	return fetch({
		spinnerToggle,
		request: () => service.create(account),
		validationCallback: validationCallback,
        callback: successCallback,
		errorCallback: errorCallback,
	});
};

export const updateAccount = function ({
	account,
	spinnerToggle,
	validationCallback,
	successCallback,
	errorCallback
}) {
	return fetch({
		spinnerToggle,
		request: () => service.update(account),
		validationCallback: validationCallback,
        callback: successCallback,
		errorCallback: errorCallback,
	});
};

export const getAccount = function ({
	userId,
	spinnerToggle,
	successCallback,
}) {
	return fetch({
		spinnerToggle,
		request: () => service.get(userId),
		callback: (r) =>
			successCallback({
				...r,
				userRole: r.userRoles[0],
				isSuperAdmin: some(r.userRoles, r => r === "superadmin")
			}),
	});
};

export const fetchAccountLinks = function ({
	userIds,
	spinnerToggle,
	successCallback,
}) {
	return fetch({
		spinnerToggle,
		userIds,
		request: () => projectSvc.getUsersLinks(userIds),
		callback: r => successCallback(r.result),
	});
};

export const modifyUserLinks = function ({
	request,
	spinnerToggle,
	successCallback,
}) {
	return fetch({
		spinnerToggle,
		request: () => projectSvc.modifyUserLinks(request),
		callback: result => successCallback(result),
	});
};

export const deleteAccount = function ({
	userId,
	spinnerToggle,
	validationCallback,
	successCallback,
}) {
	return fetch({
		spinnerToggle,
		request: () => service.delete(userId),
		validationCallback: validationCallback,
        callback: successCallback,
	});
};

export const changePassword = function ({
	request,
	spinnerToggle,
	successCallback,
	validationCallback,
}) {
	return fetch({
		spinnerToggle,
		request: () => service.resetPassword(request),
		validationCallback: validationCallback,
        callback: successCallback,
	});
};

export const changePasswords = function ({
	request,
	spinnerToggle,
	successCallback,
	validationCallback,
}) {
	return fetch({
		spinnerToggle,
		request: () => service.resetPasswords(request),
		validationCallback: validationCallback,
        callback: successCallback,
	});
};

export const fetchEditModalLookups = ({ spinnerToggle, successCallback }) => {
	return fetchParallel({
		spinnerToggle,
		requests: [
			lookupSvc.getProjectCodes,
			lookupSvc.getUserRoles,
			lookupSvc.getProjects,
		],
		callback: ([projectCodes, userRoles, projects]) =>
			successCallback({
				projectCodes: projectCodes.result.map(mapMultiselectLookup),
				userRoles: userRoles.result.map(mapLookup),
				projects: projects.result.map(mapMultiselectLookup),
			}),
	});
};

export const openAccountEditModal = data => ({
	type: types.AM_OPEN_EDIT_MODAL,
	data: data
});

export const closeAccountEditModal = () => ({
    type: types.AM_CLOSE_EDIT_MODAL,
});

export const toggleModalSpinner = toggle => ({
    type: types.AM_TOGGLE_MODAL_SPINNER,
    data: { toggle },
});

export const changeEditModalFields = (data) => ({
    type: types.AM_CHANGE_MODAL_STATE,
    data: data,
});

export const setErrorEditModal = (data) => ({
	type: types.AM_SET_ERROR_EDIT_MODAL,
    data: data,
});

export const fetchUserSettings = ({ userId, spinnerToggle, successCallback }) => {
	return fetch({
		spinnerToggle,
		request: () => userSettingsSvc.getSettings(userId),
		callback: r => successCallback(r.result),
	});
};

export const updateUserSettings = ({ userId, settings, spinnerToggle, successCallback, errorCallback }) => {
	return fetch({
		spinnerToggle,
		request: () => userSettingsSvc.updateSettings(userId, settings),
		callback: successCallback,
		errorCallback: errorCallback,
	});
};