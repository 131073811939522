import types from '../common/actionTypes';
import dotProp from 'dot-prop-immutable';


const defaultState = {
    scenarios: null,
    masterScenario: null,
    detailScenario: null,
    selectedFile: null,
    showFilesSpinner: false,
    showRowsSpinner: false,
    filesSource: null,
    rowsSource: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.DASHBOARD_COMPARER_SET_SCENARIOS:
            return {
                ...state,
                scenarios: action.data.scenarios
            };
        case types.DASHBOARD_COMPARER_SET_MASTER_SCENARIO:
            return {
                ...state,
                masterScenario: action.data.key
            };
        case types.DASHBOARD_COMPARER_SET_DETAIL_SCENARIO:
            return {
                ...state,
                detailScenario: action.data.key
            };
        case types.DASHBOARD_COMPARER_SET_FILES_SOURCE:
            return {
                ...state,
                filesSource: action.data.source
            };
        case types.DASHBOARD_COMPARER_SET_ROWS_SOURCE:
            return {
                ...state,
                rowsSource: action.data.source
            };
        case types.DASHBOARD_COMPARER_RESET_ROWS_SOURCE:
            return {
                ...state,
                rowsSource: null,
                showRowsSpinner: false
            };
        case types.DASHBOARD_COMPARER_RESET_FILES_SOURCE:
            return {
                ...state,
                filesSource: null,
                showFilesSpinner: false
            };
        case types.DASHBOARD_COMPARER_SELECT_FILE:
            return {
                ...state,
                selectedFile: action.data.idx
            };
        case types.DASHBOARD_COMPARER_TOGGLE_FILES_SPINNER:
            return {
                ...state,
                showFilesSpinner: action.data.showSpinner
            };
        case types.DASHBOARD_COMPARER_RESET_FILE:
            return {
                ...state,
                selectedFile: null
            }
        case types.DASHBOARD_COMPARER_TOGGLE_ROWS_SPINNER:
            return {
                ...state,
                showRowsSpinner: action.data.showSpinner
            };
        case types.DASHBOARD_COMPARER_TOGGLE_RESULTS_SPINNER:
            return {
                ...state,
                showResultsSpinner: action.data.showSpinner
            };
        case types.DASHBOARD_COMPARER_RESET_SCREEN:
            return { ...defaultState };

        case types.DASHBOARD_COMPARER_RESET_PAGING:
            return dotProp.set(state, 'rowsSource.filter.token', []);

        default:
            return state;
    }
}