import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super('api/tags');
    }

    getTags = filter => this.httpPostRequest(`list`, filter);

    getTag = tagId => this.httpGetRequest(`${tagId}`);

    saveTag = tag => this.httpPutRequest(null, tag);

    deleteTags = tagIds => this.httpPostRequest("delete", tagIds);

    getLinks = filter => this.httpPostRequest(`links`, filter);

    getLookup = filter => this.httpPostRequest(`lookup`, filter);

    deleteLinks = filter => this.httpPostRequest(`deleteLinks`, filter);

    saveLinks = links => this.httpPutRequest('link', links);
}
