import types from '../common/actionTypes';
import dotProp from "dot-prop-immutable";

const defaultState = {
    showGridSpinner: false,
    gridSource: {
        data: [],
        view: [],
        filter: { searchTerm: null }
    },
    accountEditModal: {
        open: false,
        locked: false,
        source: {},
        serverError: ""
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        // Set selected tab
        case types.AM_SET_GRID_SOURCE:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    data: [...action.data.data]
                }
            }
        case types.AM_SET_GRID_VIEW:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    view: [...action.data.view]
                }
            }
        case types.AM_CLEAR_GRID_VIEW:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    view: []
                }
            }
        case types.AM_CHANGE_FILTER_SEARCH_TERM:
            return {
                ...state,
                gridSource: {
                    ...state.gridSource,
                    filter: { ...state.gridSource.filter, searchTerm: action.data.searchTerm }
                }
            }
        case types.AM_TOGGLE_GRID_SPINNER:
            return {
                ...state,
                showGridSpinner: action.data.toggle
            }
        case types.AM_OPEN_EDIT_MODAL:
            return dotProp.set(state, "accountEditModal", {
                open: true,
                source: action.data
            });
        case types.AM_CLOSE_EDIT_MODAL:
            return dotProp.set(state, "accountEditModal", { ...defaultState.accountEditModal });
        case types.AM_CHANGE_MODAL_STATE:
            return dotProp.merge(state, "accountEditModal.source", action.data);
        case types.AM_TOGGLE_MODAL_SPINNER:
            return dotProp.set(state, "accountEditModal.locked", action.data.toggle);
        case types.AM_SET_ERROR_EDIT_MODAL:
            return dotProp.set(state, "accountEditModal.serverError", action.data);
        default:
            return state;
    }
}
