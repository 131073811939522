﻿import types from 'common/actionTypes';
import {mapInjectorFields, mapTabs} from 'common/enums';
import dotProp from 'dot-prop-immutable';

const defaultState = {
    mapSource: {
        taskId: null,
        data: null,
        token: null,
        map: null,
        view: {
            selectedTab: mapTabs.lmpAvg,

            icons: null,
            colors: {
                areas: null,
                [mapInjectorFields.lmpAvg]: null,
                [mapInjectorFields.pCap]: null,
            },
            hoveredArea: null,
            hoveredInjector: null,
            showAreas: true,
            selectedField: mapInjectorFields.lmpAvg,
        },

        isFiltersModalOpen: false,
        isAreasEditModalOpen: false,
        filter: {
            caseNames: null,

            startDate: null,
            endDate: null,

            areas: null,
            fuelTypes: null,
            cycles: null,
            unitTypes: null,
            periods: null,
        }
    }
};

export default (state = defaultState, action) => {

    switch (action.type) {
        case types.DASHBOARD_UPDATE_MAP_FILTER:

            const { caseNames, startDate, endDate, fuelTypes, areas, unitTypes, cycles, periods } = action.data.filter;

            if (caseNames !== undefined){
                // Merge caseNames if it provided or set null if is'nt
                const update = caseNames !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.caseNames', caseNames);
            }
            if (startDate !== undefined) {
                const update = startDate !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.startDate', startDate);
            }
            if (endDate !== undefined) {
                const update = endDate !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.endDate', endDate);
            }
            if (fuelTypes !== undefined) {
                const update = fuelTypes !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.fuelTypes', fuelTypes);
            }
            if (areas !== undefined) {
                const update = areas !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.areas', areas);
            }
            if (unitTypes !== undefined) {
                const update = unitTypes !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.unitTypes', unitTypes);
            }
            if (cycles !== undefined) {
                const update = cycles !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.cycles', cycles);
            }
            if (periods !== undefined) {
                const update = periods !== null ? dotProp.merge : dotProp.set;

                state = update(state, 'mapSource.filter.periods', periods);
            }

            return state;

        case types.DASHBOARD_MAP_SET_TASK:
            return dotProp.set(state, 'mapSource.taskId', action.data.taskId);

        case types.DASHBOARD_SET_MAP_RESULT:
            return dotProp.set(state, 'mapSource.data', action.data.result);

        case types.DASHBOARD_RESET_MAP_RESULT:
            return dotProp.set(state, 'mapSource.data', null);

        case types.DASHBOARD_SET_MAP_COLORS:
            return dotProp.merge(state, 'mapSource.view.colors', action.data.colors);

        case types.DASHBOARD_SET_MAP_HOVERED_AREA:
            return dotProp.set(state, 'mapSource.view.hoveredArea', action.data);

        case types.DASHBOARD_SET_MAP_HOVERED_INJECTOR:
            return dotProp.set(state, 'mapSource.view.hoveredInjector', action.data);

        case types.DASHBOARD_SET_MAP_TOKEN:
            return dotProp.set(state, 'mapSource.token', action.data.token);

        case types.DASHBOARD_SET_MAP:
            return dotProp.set(state, 'mapSource.map', action.data.map);

        case types.DASHBOARD_SET_MAP_SELECTED_TAB:
            return dotProp.set(state, 'mapSource.view.selectedTab', action.data.tab);

        case types.DASHBOARD_SET_MAP_SELECTED_FIELD:
            return dotProp.set(state, 'mapSource.view.selectedField', action.data.field);

        case types.DASHBOARD_SET_MAP_ICONS:
            return dotProp.set(state, 'mapSource.view.icons', action.data.icons);

        case types.DASHBOARD_SET_MAP_SHOW_AREAS:
            return dotProp.set(state, 'mapSource.view.showAreas', action.data.show);

        case types.DASHBOARD_MAP_CHANGE_UNIT_TYPE_MODE:
            for(let [index, { key }] of state.mapSource.filter.unitTypes.items.entries()) {
                if (action.data.keys.indexOf(key) !== -1) {
                    state = dotProp.set(state, `mapSource.filter.unitTypes.items.${index}.mode`, action.data.mode)
                }
            }
            return state;

        case types.DASHBOARD_MAP_RESET_VIEW:
            // Icons don't change
            let newState = dotProp.set(state, 'mapSource.view.selectedTab', mapTabs.lmpAvg);
            newState = dotProp.set(newState, 'mapSource.view.colors', defaultState.mapSource.view.colors);
            newState = dotProp.set(newState, 'mapSource.view.showAreas', true);
            return dotProp.set(newState, 'mapSource.view.selectedField', mapInjectorFields.lmpAvg);

        case types.DASHBOARD_MAP_TOGGLE_FILTERS_MODAL:
            return dotProp.toggle(state, 'mapSource.isFiltersModalOpen')

        case types.DASHBOARD_MAP_TOGGLE_AREAS_EDIT_MODAL:
            return dotProp.toggle(state, 'mapSource.isAreasEditModalOpen')

        default:
            return state;
    }
}