import types from "../common/actionTypes";
import { staticReportLevels } from "../common/enums";
import dotProp from "dot-prop-immutable";

const defaultFilter = {
    pageSize: 10,
    pageNumber: 1,
    searchString: "",
};

const defaultState = {
    // spinners
    masterSpinner: false,

    //db_name for getTables
    settings: {},

    //editor tab
    cases: {
        data: [],
        filter: {
            ...defaultFilter,
        },
    },

    tables: {
        tablesTypeBase: [],

        tablesTypeView: [],

        tablesTypeDashboard: [],
    },

    editorTabs: {
        tabs: [{
            id: 1,
            query: '',
            isResultLoading: false,
            results: {
                queryExecutionId: "",
                nextToken: "",
                totalCount: null,
                queryResult: "",
                gridColumns: null,
                gridRows: null
            }
        }],
        activeTab: 1,
    },

    //saved reports tab
    staticReports: {
        data: [],
    },

    templates: {
        data: [],
    },

    //report history
    athenaHistories: {
        data: [],
        filter: {
            searchString: null,
            pageNumber: 1,
            pageSize: 50,
            hasLinkedCases: null
        }
    },

    editModal: {
        open: false,
        item: {
            staticReportName: '',
            description: '',
            query: '',
            templateIds: [],
            global: false,
        }
    },

    //Task 3589 on-hold
    dashboardModal: {
        open: false,
        dashboards: [],
        formData: {
            columns: [],
            name: '',
            partitions: [],
        },
    },
    //

    endQuerys: [],

    // selections
    casesSelected: [],
    templatesSelected: [],
    reportsSelected: [],

    reportingLookup: {},

    masterLevel: staticReportLevels.editor,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.SR_SET_MASTER_LEVEL:
            return dotProp.set(state, "masterLevel", action.data.level);
        case types.SR_TOGGLE_MASTER_SPINNER:
            return dotProp.set(state, "masterSpinner", action.data.showSpinner);
        case types.SR_TOGGLE_RESULT_SPINNER:
            const tabIndex = state.editorTabs.tabs.findIndex(tab => tab.id === action.payload.tabId);
            return dotProp.set(state, `editorTabs.tabs.${tabIndex}.isResultLoading`, action.payload.isLoading);
        case types.SR_SET_LOOKUPS:
            return dotProp.set(state, "reportingLookup", action.data);
        case types.SR_SET_SETTINGS:
            return dotProp.set(state, "settings", action.data);
        case types.SR_SET_CASES:
            return dotProp.set(state, "cases", cases => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...cases.filter, ...action.data.filter },
            }));
        case types.SR_SET_CASES_SELECTED:
            return dotProp.set(state, "casesSelected", action.data?.selectedIds ?? []);
        case types.SR_SET_CASES_FILTER:
            return dotProp.merge(state, "cases.filter", action.data);
        case types.SR_SET_TABLES:
            return dotProp.set(state, "tables", action.data);
        case types.SR_SET_ACTIVE_TAB:
            return dotProp.set(state, "editorTabs.activeTab", action.payload);
        case types.SR_ADD_NEW_TAB:
            return dotProp.set(state, "editorTabs", tabs => ({
                ...tabs,
                tabs: [...tabs.tabs, action.payload],
                activeTab: action.payload.id,
            }));
        case types.SR_CLOSE_TAB:
            const filteredTabs = state.editorTabs.tabs.filter(tab => tab.id !== action.payload);
            const newActiveTab = filteredTabs.length > 0 ? filteredTabs[filteredTabs.length - 1].id : 1;

            return dotProp.set(state, "editorTabs", tabs => ({
                ...tabs,
                tabs: filteredTabs,
                activeTab: newActiveTab,
            }));
        case types.SR_CHANGE_TAB_CONTENTS:
            const { tabId, query } = action.payload;
            return dotProp.set(state, `editorTabs.tabs`, tabs =>
                tabs.map(tab => tab.id === tabId ? { ...tab, query } : tab)
            );
        case types.SR_SET_RESULT_DATA:
            {
                const { tabId, data } = action.payload;

                state = dotProp.set(state, `editorTabs.tabs.${state.editorTabs.tabs.findIndex(tab => tab.id === tabId)}.isResultLoading`, false);
                return dotProp.set(state, `editorTabs.tabs`, tabs =>
                    tabs.map(tab => tab.id === tabId ? { ...tab, results: { ...tab.results, ...data } } : tab)
                );
            }
        case types.SR_SET_GRID_COLUMNS:
            {
                const { tabId, data } = action.payload;
                return dotProp.set(state, `editorTabs.tabs`, tabs =>
                    tabs.map(tab => tab.id === tabId ? { ...tab, results: { ...tab.results, gridColumns: data } } : tab)
                );
            }
        case types.SR_SET_GRID_ROWS:
            {
                const { tabId, data } = action.payload;
                return dotProp.set(state, `editorTabs.tabs`, tabs =>
                    tabs.map(tab => tab.id === tabId ? { ...tab, results: { ...tab.results, gridRows: data } } : tab)
                );
            }
        case types.SR_SET_STATIC_REPORTS:
            return dotProp.set(state, "staticReports", reports => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...reports.filter, ...action.data.filter },
            }));
        case types.SR_SET_REPORT_SELECTED:
            return dotProp.set(state, "reportsSelected", action.data?.selectedIds ?? []);
        case types.SR_SET_TEMPLATE:
            return dotProp.set(state, "templates", templates => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...templates.filter, ...templates.data.filter },
            }));
        case types.SR_SET_TEMPLATE_SELECTED:
            return dotProp.set(state, "templatesSelected", action.data?.selectedIds ?? []);
        case types.SR_SET_ATHENA_HISTORIES:
            return dotProp.set(state, "athenaHistories", source => ({
                data: action.data.data,
                total: action.data.total,
                filter: { ...source.filter, ...action.data.filter },
            }));
        case types.SR_SET_ATHENA_FILTER:
            return dotProp.merge(state, "athenaHistories.filter", action.data);
        case types.SR_RESET_EDITOR_TAB:
            state = dotProp.set(state, 'cases.data', []);
            state = dotProp.set(state, 'cases.filter', { ...defaultFilter });
            state = dotProp.set(state, 'casesSelected', []);
            return state;
        case types.SR_OPEN_EDIT_MODAL:
            return dotProp.set(state, "editModal", {
                open: true,
                title: action.data.title,
                item: action.data.item,
                templates: action.data.templates,
                activeTabQuery: action.data.activeTabQuery
            });
        case types.SR_CLOSE_EDIT_MODAL:
            return dotProp.set(state, "editModal", { ...defaultState.editModal });
        case types.SR_EDIT_MODAL_CHANGE_FIELDS:
            return dotProp.merge(state, "editModal.item", action.data);
        case types.SR_SET_TABS:
            return dotProp.set(state, "editorTabs.tabs", action.payload);
        case types.SR_SET_END_QUERYS:
            return dotProp.set(state, "endQuerys", action.payload);
        case types.SR_RESET_RESULT_DATA:
            state = dotProp.set(state, `editorTabs.tabs.${state.editorTabs.tabs.findIndex(tab => tab.id === action.payload)}.isResultLoading`, false);
            return dotProp.set(state, `editorTabs.tabs`, tabs =>
                tabs.map(tab => tab.id === action.payload ? {
                    ...tab, results: {
                        queryExecutionId: "",
                        nextToken: "",
                        totalCount: null,
                        queryResult: "",
                        gridColumns: [],
                        gridRows: []
                    }
                } : tab)
            );
        case types.SR_RESET:
            return { ...defaultState, endQuerys: state.endQuerys };
        //Task 3589 on-hold
        case types.SR_OPEN_DASHBOARD_MODAL:
            return dotProp.set(state, "dashboardModal", {
                ...state.dashboardModal,
                open: true,
            });
        case types.SR_CLOSE_DASHBOARD_MODAL:
            return dotProp.set(state, "dashboardModal", { ...defaultState.dashboardModal });
        case types.SR_DASHBOARD_MODAL_CHANGE_FIELDS:
            return dotProp.merge(state, "dashboardModal.formData", action.data);
        case types.SR_RESET_DASHBOARD_MODAL:
            return dotProp.set(state, 'dashboardModal.formData', {
                columns: [],
                name: '',
                partitions: [],
            });
        //
        default:
            return state;
    }
};
