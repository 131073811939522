import BaseService from "./baseService";

export default class extends BaseService {
	constructor() {
		super("projectMgmt");
	}

    /**
     * Gets project information
     * @param projectId Project identifier
     * @returns Project information
     */
	getProject = projectId => this.httpGetRequest(projectId);

    /**
     * Gets list of projects applying filter 
     * @param filter JSON (applying filters)
     * @returns List of projects
     */
	getProjects = (filter = {}) => this.httpPostRequest("list", filter);

    /**
     * Creates/updates project
     * @param userId User guid
     * @returns Project id
     */
	putProject = project => this.httpPutRequest(null, project);

    /**
     * Delete project
     * @param userId User guid
     */
	deleteProject = projectId => this.httpDeleteRequest(projectId);

    /**
     * Archives project
     * @param userId User guid
     */
	archiveProject = projectId => this.httpPostRequest(`archive/${projectId}`);

    /**
     * Gets links for user(or current user of null)
     * @param userId User guid
     * @returns User link info
     */
	getUserLinks = userId => this.httpGetRequest(`links/${userId}`);

    /**
     * Gets users list with links
     * @param userIds List of user guids
     * @returns List of users and their links
     */
	getUsersLinks = userIds => this.httpPostRequest("links/list", userIds);

    /**
     * Updates user links
     * @param request JSON { userId: guid, projectIds: int[], projectCodes: string[]}
     */
    modifyUserLinks = request => this.httpPostRequest("links", request);

    /**
     * Force delete project
     * @param projectId Project id
     */
    forceDeleteProject = projectId => this.httpDeleteRequest(`forceDelete/${projectId}`);
}
