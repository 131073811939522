import BaseService from './baseService';

export default class extends BaseService {
    constructor() {
        super('lookups');
    }

    getProjects = () => this.httpGetRequest('projectList');

    getUserRoles = () => this.httpGetRequest('userRolesList');

    getProjectCodes = () => this.httpGetRequest('projectCodesList');

    getReleases = taskId => this.httpGetRequest(`releases/${taskId ?? ''}`);

    getMarketModels = releaseId => this.httpGetRequest(`marketModels/${releaseId}`);

    getCubeTemplates = taskId => this.httpGetRequest(`cubeTemplates/${taskId}`);

    getVmSizes = () => this.httpGetRequest('vmSizes');

    getPartitioningModes = () => this.httpGetRequest('partitioningModes');

    getTimeUnits = () => this.httpGetRequest('timeUnits');

    getReportTemplates = taskId => this.httpGetRequest(`reportTemplates/${taskId}`);

    getReportingLookup = taskId => this.httpGetRequest(`reportingLookup/${taskId}`);

    getScopes = () => this.httpGetRequest('scopes');

    getLibraries = taskId => this.httpGetRequest(`libraries/${taskId}`);

    getVMGroups = () => this.httpGetRequest(`vmGroups`);

    getSOMModes = () => this.httpGetRequest(`somModes`);

    getSOMReleases = () => this.httpGetRequest('somReleases');

    getTaskReleaseGroups = releaseGroupId => this.httpGetRequest(`release-groups?${releaseGroupId}`);
}
